type MessageHandler = (e: any) => void

export class IframeUtil {
  static inIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  static publishMessageInIframe({message}: {message: string}): void {
    window.parent.postMessage(message, '*')
  }

  private static globalHandler: MessageHandler
  private static handlers: { [key: string]: MessageHandler } = {}
  private static listeningToIframeMessages: boolean = false

  static listenForMessagesFromIframe({message, handler}: { message?: string; handler: MessageHandler }) {

    if (!message?.length) {
      IframeUtil.globalHandler = handler
    } else {
      IframeUtil.handlers[message] = handler
    }

    if (IframeUtil.listeningToIframeMessages) return

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent'
    const messageEvent = eventMethod === 'attachEvent'
      ? 'onmessage'
      : 'message'
    // @ts-ignore
    window[eventMethod](messageEvent, function(e: any) {

      IframeUtil.globalHandler?.(e)

      const handler = Object.keys(IframeUtil.handlers)
        .find((handler) => e.data === handler || e.message === handler)

      IframeUtil.handlers[handler]?.(e)
    })
  }
}
