export enum InvalidTokenComingFromParameter {
  RESET_PASSWORD = "reset-password",
  REGISTRATION_COMPLETE = "registration-complete",
}

export class AuthURLs {
  home() {
    return "/auth";
  }
  login(redirectUrl?: string) {
    let path = `${this.home()}/login`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  logout(redirectUrl?: string) {
    let path = `${this.home()}/logout`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  registration() {
    return `${this.home()}/registration`;
  }
  registrationComplete() {
    return `${this.home()}/registration/complete`;
  }
  passwordForgot() {
    return `${this.home()}/password/forgot`;
  }
  passwordReset() {
    return `${this.home()}/password/reset`;
  }
  invalidToken(comingFrom: InvalidTokenComingFromParameter) {
    return `${this.home()}/invalid-token/${comingFrom}`;
  }
}
