export enum ESGSelfAssessmentState {
  ESG_SELF_ASSESSMENT_NOT_STARTED = "ESG_SELF_ASSESSMENT_NOT_STARTED",
  ESG_SELF_ASSESSMENT_IN_PROGRESS = "ESG_SELF_ASSESSMENT_IN_PROGRESS",
  ESG_SELF_ASSESSMENT_IN_REVIEW = "ESG_SELF_ASSESSMENT_IN_REVIEW",
  ESG_SELF_ASSESSMENT_COMPLETED = "ESG_SELF_ASSESSMENT_COMPLETED",
}

export interface ESGSelfAssessment {
  id: string;
  companyInfo: {
    id: string;
    name: string;
  };
  evaluationPeriod: number;
  state: ESGSelfAssessmentState;
  surveyTemplate: {
    id: string;
    name: string;
  };
  surveyId: string;
}
