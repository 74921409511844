import { RouteConfig } from "vue-router";

export const partnerRoutes: Array<RouteConfig> = [
  {
    path: ``,
    name: "Partner Companies",
    component: () => import("@/views/partners/overview/index.vue"),
  },
];

if (process.env.VUE_APP_DEMO_DASHBOARD_ENABLED === "true") {
  partnerRoutes.push({
    path: `dashboard/:dashboardType`,
    name: "Partner Dashboard",
    component: () => import("@/views/partners/demo-dashboard/overview/index.vue"),
  });
}
