import { RouteConfig } from "vue-router";

export const surveysRoutes: Array<RouteConfig> = [
  {
    path: ``,
    component: () => import("@/views/surveys/index.vue"),
  },
  {
    path: `esg-self-assessment/:id`,
    component: () => import("@/views/surveys/esg-self-assessment/index.vue"),
  },
  {
    path: `esg-rating-request/:id`,
    component: () => import("@/views/surveys/esg-rating-request/index.vue"),
  },
  {
    path: `lksg-rating-request/:id`,
    component: () => import("@/views/surveys/lksg-rating-request/index.vue"),
  },
];
