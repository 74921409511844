export const messages = {
  validity_period_is_start_switch: "Ist Start?",
  validity_period_start: "Start",
  validity_period_valid_from: "Gültig ab",
  validity_period_is_end_switch: "Ist Ende?",
  validity_period_end: "Ende",
  validity_period_valid_until: "Gültig bis",
  validity_add_version: "Version hinzufügen",
  validity_period_initial_version: "Erstversion",
  validity_period_set_starting_version_date: "Startdatum für die Anfangsversion festlegen",
  validity_period_starting_from: "Beginnend ab",
  validity_period_starting_date: "Version Start Datum",
};
