import ToastWrapper from "@gc/legacy-components/src/layout/toast-wrapper.vue";
import ErrorToastWrapper from "@gc/legacy-components/src/layout/error-toast-wrapper.vue";

export default {
  methods: {
    successToast(msg: string) {
      // @ts-ignore
      this.$toast.success({
        component: ToastWrapper,
        props: {
          msg,
        },
        position: "top-right",
        showCloseButtonOnHover: true,
      });
    },
    errorToast(msg: string) {
      // @ts-ignore
      this.$toast.error({
        component: ErrorToastWrapper,
        props: {
          msg,
        },
        position: "top-right",
        showCloseButtonOnHover: true,
      });
    },
    warningToast(msg: string) {
      // @ts-ignore
      this.$toast.warning({
        component: ToastWrapper,
        props: {
          msg,
        },
        position: "top-right",
        showCloseButtonOnHover: true,
      });
    },
  },
};
