export interface RatingInfo {
  result: RatingResult;
  validUntil: number;
  details: RatingCategoryDetail[];
}

export interface RatingResult {
  score: string;
  grade: RatingFlag;
}

export interface RatingCategoryDetail {
  score: string;
  category: string;
}

export enum RatingFlag {
  A_PLUS = "A_PLUS",
  A = "A",
  A_MINUS = "A_MINUS",
  B_PLUS = "B_PLUS",
  B = "B",
  B_MINUS = "B_MINUS",
  C_PLUS = "C_PLUS",
  C = "C",
  C_MINUS = "C_MINUS",
  D_PLUS = "D_PLUS",
  D = "D",
  D_MINUS = "D_MINUS",
}
