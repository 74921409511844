import { messages as administration } from "./administration/en";
import { messages as partners } from "./partners/en";
import { messages as sco2pes } from "./sco2pes/en";
import { messages as esg } from "./esg/en";
import { messages as support } from "./support/en";
import { messages as welcome } from "./welcome/en";
import { messages as surveys} from "./surveys/en";
import { messages as faq} from "./faq/en";

export const messages = {
  ...administration,
  ...partners,
  ...sco2pes,
  ...esg,
  ...support,
  ...welcome,
  ...surveys,
  ...faq
};
