/* eslint-disable */
export const messages = {
  customer_partners_partner_companies: "Partnerunternehmen",
  customer_partners_connect_with_partners: "Verbindung mit Partnern",
  customer_partners_connected: "Verbunden",
  customer_partners_pending: "Ausstehend",
  customer_partners_receive_invitation: "Ich habe einen Einladungscode von einer Partnerorganisation <b>erhalten</b>.",
  customer_partners_send_invitation:
    "Ich möchte eine Partnerorganisation <b>einladen</b>, indem ich einen Einladungscode sende.",
  customer_partners_receive_invitation_1:
    "Geben Sie bitte den Einladungscode den Sie per E-Mail erhalten haben ein, um die eigene Organisation mit einer anderen zu verbinden:",
  customer_partners_invitation_code: "Einladungscode",
  customer_partners_receive_invitation_2: "Die Organisation wurde zu Ihren Partnerorganisationen hinzugefügt.",
  customer_partners_receive_invitation_3:
    "Sie können einen Namen für die Organisation angeben und weitere Daten hinzufügen:",
  customer_partners_next: "Weiter",
  customer_partners_partner_information: "Partner Details",
  customer_partners_at_least_one_product_selected: "Mindestens ein Produkt muss ausgewählt sein.",
  customer_partners_company_name: "Unternehmens-Name",
  customer_partners_product: "Produkte",
  customer_partners_organisation_name: "Organisationsname",
  customer_partners_invitation_title: "Partner-Einladungsprozess",
  customer_partners_invitation_point_1: "1. Sie laden Partner per Excel Import ein",
  customer_partners_invitation_point_2: "2. Die GLOBAL SUITE versendet Einladungs-E-Mails an die Partner",
  customer_partners_invitation_point_3:
    "3. Die Partner registrieren sich auf der GLOBAL SUITE (falls diese nicht bereits registriert sind) oder nehmen die Einladung an",
  customer_partners_invitation_point_4:
    "4. Die Partnerschaft ist erstellt und Sie können mit dem Datenaustausch beginnen",
  customer_partners_received_information: "Haben Sie eine Einladung erhalten?",
  customer_partners_import_partners: "Partner Importieren",
  customer_partners_import_partners_description:
    "Partner können per Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",
  customer_partners_copy_registration_url: "Kopieren Sie den Registrierungslink",
  customer_partners_copied_registration_url: "Registrierungslink kopiert",
  customer_partners_unique_id: "Eindeutige ID",
  customer_partners_invitation_information: "Einladungs-Informationen",
  customer_partners_dashboard: "Partner-Dashboards",
  customer_partners_upload_success: "Daten erfolgreich importiert",
  customer_partners_search: "Suche nach Firmennamen, Eindeutiger ID, E-Mail, Kontaktperson",
};
