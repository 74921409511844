import { isEmpty } from "lodash";

export class ContentDisposition {
  static instance: ContentDisposition = new ContentDisposition();

  public extractFileName(contentDispositionHeader: string, fallbackFileName: string): string {
    if (contentDispositionHeader && contentDispositionHeader.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        const filename = matches[1].replace(/['"]/g, "");
        if (!isEmpty(filename)) return filename;
      }
    }
    return fallbackFileName;
  }
}
