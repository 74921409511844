import { RouteConfig } from "vue-router";

export const supportRoutes: Array<RouteConfig> = [
  {
    path: ``,
    component: () => import("@/views/support/index.vue"),
  },
  {
    path: `:id`,
    component: () => import("@/views/support/index.vue"),
  },
];
