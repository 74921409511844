import store from "@/store";
import {GenericApi, Api, IframeUtil} from "@gc/helpers";
import { StringHelper } from "@gc/helpers/src/string-helper";
import { SelectedCompanyUrl, URLs } from "@gc/urls";

declare module "vue/types/vue" {
  interface Vue {
    $api: GenericApi;
  }
}

export const api = new Api(
  process.env.VUE_APP_ENDPOINT,
  StringHelper.instance.parseBoolean(process.env.VUE_APP_LOG_AXIOS_FAILURES, false),
  (o) => {
    if (!IframeUtil.inIframe()) {
      let url: string
      if (o.isPermaLink) {
        url = `${process.env.VUE_APP_CSRD_URL}/perma-link/${store.state.permaLinkToken}`
      } else {
        url = `${process.env.VUE_APP_PUBLIC_URL}/${URLs.customer.logout(window.location.href)}`
      }
      window.location.replace(url)
    } else {
      if (o.isPermaLink) {
        IframeUtil.publishMessageInIframe({message: "on-401:perma-link"})
      } else {
        IframeUtil.publishMessageInIframe({message: "on-401"})
      }
    }
  },
  () => {
    const headers: Record<string, string> = {};
    headers['X-LANGUAGE'] = store.state.settings.lang;

    const companyId = SelectedCompanyUrl.extractId();
    if (companyId) {
      headers['X-COMPANY'] = companyId;
    }

    const permaLinkToken = store.state.permaLinkToken
    if (permaLinkToken?.length) {
      headers['X-PERMA-LINK'] = permaLinkToken
    }

    return headers;
  }
);

export default {
  install(Vue: any) {
    Vue.prototype.$api = new GenericApi(api);
  },
};
