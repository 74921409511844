/* eslint-disable */
export const messages = {
  system_admin_survey_surveys: "Surveys",
  system_admin_survey_details: "Umfrage Details",
  system_admin_survey_state: "Status",
  system_admin_survey_state_in_progress: "In Bearbeitung",
  system_admin_survey_state_in_review: "In Review",
  system_admin_survey_state_completed: "Beendet",
  system_admin_survey_company: "Unternehmen",
  system_admin_survey_survey: "Umfrage",
  system_admin_survey_result: "Resultat",
  system_admin_survey_events: "Events",
  system_admin_survey_evaluation_period: "Evaluierungszeitraum",
  system_admin_survey_survey_page: "Umfrage Seite",
  system_admin_survey_finalise_survey: "Review beenden",
  system_admin_survey_calculated_result: "Das folgende Resultat wurde berechnet:",

  system_admin_survey_edit_kpi_value: "KPI-Wert bearbeiten",
  system_admin_survey_kpi: "KPI",
  system_admin_survey_expression: "Formel",
  system_admin_survey_expression_parameters: "Formelparameter",
  system_admin_survey_expression_result: "Formelergebnis",
  system_admin_survey_expression_errrors: "Fehler",
  system_admin_survey_edit_kpi_info: "Berechnete Werte können überschrieben werden.",
  system_admin_survey_kpi_value: "KPI Wert",

  system_admin_survey_edit_score_value: "Ergebniswert bearbeiten",
  system_admin_survey_category: "Kategorie",
  system_admin_survey_max_score: "Maximale Punktzahl",
  system_admin_survey_edit_score_info: `Berechnete Werte können überschrieben werden.<br /> Einige Scores müssen manuell genehmigt werden, z.B. wenn ein Datei-Upload verifiziert werden muss.`,

  system_admin_survey_customers_must_submit_answers: "Kunden müssen zuerst alle Antworten einreichen",

  system_admin_survey_kpis: "KPIs",
  system_admin_survey_with_errors: "Mit Fehlern",
  system_admin_survey_without_errors: "Ohne Fehler",
  system_admin_survey_no_kpis: "Keine KPIs",
  system_admin_survey_kpis_title: "Titel",
  system_admin_survey_kpis_expression: "Formel",
  system_admin_survey_kpis_calculated_value: "Berechneter Wert",
  system_admin_survey_kpis_value: "Wert",
  system_admin_survey_kpis_errors: "Fehler",
  system_admin_survey_kpis_actions: "",

  system_admin_survey_scores: "Ergebnisse",
  system_admin_survey_approved: "Akzeptiert",
  system_admin_survey_not_approved: "Nicht bestätigt",
  system_admin_survey_no_scores: "Keine Noten",
  system_admin_survey_scores_category: "Ergebniskategorie",
  system_admin_survey_scores_expression: "Expression",
  system_admin_survey_scores_calculated_value: "Berechneter Wert",
  system_admin_survey_scores_score: "Score",
  system_admin_survey_scores_errors: "Fehler",
  system_admin_survey_scores_approved: "Akzeptiert",
};
