export class IntegrationURLs {
  home() {
    return `/integration`;
  }
  verifyScorecard() {
    return `${this.home()}/scorecard-verification`;
  }
  answerCommuterSurvey() {
    return `${this.home()}/answer-commuter-survey`;
  }
  answerCombinedCommuterSurvey() {
    return `${this.home()}/answer-combined-commuter-survey`;
  }
  survey() {
    return `${this.home()}/survey`;
  }
}
