/* eslint-disable */
export const messages = {
  system_admin_co2_database_title: "CO<sub>2</sub>-Typen",
  system_admin_co2_database_freetext_search: "Freitext Suche (ID/Name)",
  system_admin_co2_database_type_description: "Typ Beschreibung",
  system_admin_co2_database_unit: "Einheit",
  system_admin_co2_database_co2_equivalent: "CO<sub>2</sub>-Äquivalent",
  system_admin_co2_database_kg_equivalent: "Kg Äquivalent",
  system_admin_co2_database_description_in_english: "Beschreibung in Englisch",
  system_admin_co2_database_description_in_german: "Beschreibung in Deutsch",
  system_admin_co2_database_co2_type: "CO<sub>2</sub>-Typ",
  system_admin_co2_database_quantity_type: "Quantity Type",
  system_admin_co2_database_type_active_checkbox: "CO<sub>2</sub>-Typ aktiv",
  system_admin_co2_database_type_valid_versions:
    "Keine Lücken und keine Überschneidungen in der Gültigkeit der Versionen",
  system_admin_co2_database_type_valid: "Alle erforderlichen Daten gegeben",
  system_admin_co2_database_co2_type_successfully_created: "CO<sub>2</sub>-Typ erfolgreich erstellt",
  system_admin_co2_database_co2_type_successfully_edited: "CO<sub>2</sub>-Typ erfolgreich bearbeitet",
  system_admin_co2_database_co2_type_successfully_deleted: "CO<sub>2</sub>-Typ erfolgreich gelöscht",
  system_admin_co2_database_co2_categories: "CO<sub>2</sub>-Kategorien",
  system_admin_co2_database_co2_category_created: "CO<sub>2</sub>-Kategorie erfolgreich erstellt",
  system_admin_co2_database_co2_category_edited: "CO<sub>2</sub>-Kategorie erfolgreich bearbeitet",
  system_admin_co2_database_co2_category_deleted: "CO<sub>2</sub>-Kategorie erfolgreich gelöscht",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_category:
    "Möchten Sie die CO<sub>2</sub> Kategorie wirklich löschen",
  system_admin_co2_database_new_co2_category: "Neue CO<sub>2</sub>-Kategorie",
  system_admin_co2_database_edit_co2_category: "CO<sub>2</sub>-Kategorie bearbeiten",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_type:
    "Möchten Sie den CO<sub>2</sub> Typ wirklich löschen",
  system_admin_co2_database_duplicate_type_title: "CO<sub>2</sub> Typ duplizieren?",
  system_admin_co2_database_duplicate_type_subtitle:
    "Die Details können vor dem Speichern des neuen CO<sub>2</sub> Typen bearbeitet werden",
  system_admin_co2_database_origin_database_reference: "Referenz in der Ursprungsdatenbank",
  system_admin_co2_database_origin_database: "Ursprungsdatenbank",
  system_admin_co2_database_co2_category: "CO<sub>2</sub>-Kategorie",
  system_admin_co2_database_versions: "Versionen",
  system_admin_co2_database_version: "Version",
  system_admin_co2_database_from: "Von",
  system_admin_co2_database_until: "Bis",
  system_admin_co2_database_dialog_date_not_valid:
    "<strong>Von</strong> muss vor oder gleich <strong>Bis</strong> sein",
  system_admin_co2_database_dialog_kg_not_valid:
    "Kg Äquivalent muss bei gewählter Einheit <strong>kg</strong> stets den Wert 1 besitzen",
  system_admin_co2_database_version_delete_confirmation_title: "Version löschen",
  system_admin_co2_database_version_delete_confirmation_message: "Wollen Sie die Version wirklich löschen?",
  system_admin_co2_database_no_version_message: "Mindestens eine Version wird benötigt",
  system_admin_co2_database_versions_not_valid:
    "Es muss stets exakt eine Version pro Buchungsperiode existieren (keine Überschneidungen, keine Lücken). Die erste Version must gültig von 0000-01 sein, die letzte Version muss gültig bis 9999-12 sein.",
  system_admin_co2_database_versions_kg_not_valid:
    "Kg Äquivalent muss bei gewählter Einheit <strong>kg</strong> stets den Wert 1 besitzen",
  system_admin_co2_database_new_version: "Neue Version",
  system_admin_co2_database_edit_version: "Version bearbeiten",
  system_admin_co2_database_administration: "Administration",
  system_admin_co2_database_created_at: "Erstellt am",
  system_admin_co2_database_versions_count: "Anzahl der Versionen",
  system_admin_co2_database_only_global: "Nur global CO<sub>2</sub> Typen",
  system_admin_co2_database_selected_co2_types_updated: "Ausgewählte CO<sub>2</sub>-Typen erfolgreich aktualisiert",
  system_admin_co2_database_confirm_activating_co2_types:
    "Möchten Sie wirklich die {selectedCo2TypesCount} ausgewählten CO<sub>2</sub>-Typen aktivieren?",
  system_admin_co2_database_confirm_deactivating_co2_types:
    "Möchten Sie wirklich die {selectedCo2TypesCount} ausgewählten CO<sub>2</sub>-Typen deaktivieren?",
  system_admin_co2_database_co2_usage_title: "Unternehmen, die diesen CO<sub>2</sub>-Typ nutzen",
  system_admin_co2_database_co2_usage_tagged_count: "Anzahl der Markierungen",
  system_admin_co2_database_co2_usage_unit_booking_count: "Anzahl der Unit-Buchungen",
  system_admin_co2_database_co2_usage_pending_unit_booking_count: "Anzahl der ausstehenden Unit-Buchungen",
  system_admin_co2_database_co2_usage_supplier_count: "Anzahl der Lieferanten",
  system_admin_co2_database_co2_usage_financial_model_count: "Anzahl der Finanzmodelle",
  system_admin_co2_database_co2_usage_commuter_survey_count: "Anzahl der Pendlerumfragen",

  system_admin_co2_database_affected_title: "Diese Unternehmen sind betroffen",
  system_admin_co2_database_affected_subtitle: "Bevor Sie speichern, überprüfen Sie bitte die folgenden Unternehmen, die von diesen Änderungen betroffen sind.",
  system_admin_co2_database_affected_none_title: "Von diesem Update sind keine Unternehmen betroffen",
  system_admin_co2_database_affected_none_subtitle: "Sie können fortfahren, indem Sie auf die Schaltfläche „Speichern“ klicken.",
  system_admin_co2_database_affected_company: "Unternehmen",
  system_admin_co2_database_affected_co2_type_prices: "CO<sub>2</sub> Typ Preise",
  system_admin_co2_database_affected_financial_models: "Finanzmodelle",
  system_admin_co2_database_affected_transport_routes: "Transportwege",
  system_admin_co2_database_affected_commuter_surveys: "Pendlerbefragungen",
  system_admin_co2_database_affected_unit_bookings: "Einheitenbuchungen",
};
