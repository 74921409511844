export function getPublicPath() {
  return process.env.BASE_URL;
}

declare module "vue/types/vue" {
  interface Vue {
    $getPublicPath: () => string;
  }
}

export default {
  getPublicPath,
  install(Vue: any) {
    Vue.prototype.$getPublicPath = getPublicPath;
  },
};
