<template>
  <router-view />
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  async mounted() {
    const language = this.$store.state.settings.lang;
    this.$root.$vuetify.lang.current = language;
    this.$root.$i18n.locale = language;

    addEventListener(
      "leavepictureinpicture",
      () => {
        const videos = Array.from(document.getElementsByTagName("video"));
        videos.forEach((video) => video.pause());
      },
      false
    );
  },
});
</script>
