import { SessionStorage } from "@gc/helpers";
import { License } from "./license";
import { Address } from "./address";
import { Role } from "./user";
import { extend } from "lodash";
import { CompanyFeatureFlags } from "./feature-flags";

interface IdentityCompanyProps {
  companyId: string;
  companyName: string;
  logoUrl?: string;
  roles: Role[];
  licenses: License[];
  address?: Address;
  industry: string;
  featureFlags: CompanyFeatureFlags;
}

export class IdentityCompany implements IdentityCompanyProps {
  constructor(props?: IdentityCompanyProps) {
    if (props) {
      const { companyId, companyName, logoUrl, roles, licenses, industry, address, featureFlags } = props;
      this.companyId = companyId;
      this.logoUrl = logoUrl;
      this.address = { ...address };
      this.companyName = companyName;
      this.roles = [...roles];
      this.licenses = [...licenses];
      this.industry = industry;
      this.featureFlags = featureFlags;
    }
  }

  companyId: string = "";
  companyName: string = "";
  logoUrl?: string = "";
  roles: Role[] = [];
  licenses: License[] = [];
  address?: Address;
  industry: string = "";
  featureFlags: CompanyFeatureFlags = { enablePcf: false, integration: false, showPendingBookingPages: false };

  public isCompanyAdmin(): boolean {
    return this.roles.includes(Role.COMPANY_ADMIN);
  }
}

interface IdentityProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  systemRoles: Role[];
  companies: IdentityCompany[];
  loginExpiresAt: number;
  isSupportUser: boolean;
  showPendingBookingPages: boolean;
}

export class Identity implements IdentityProps {
  private static readonly storageKey: string = "identity";

  constructor(props?: IdentityProps) {
    if (!props) return;
    const {
      id,
      firstName,
      lastName,
      email,
      systemRoles,
      companies,
      loginExpiresAt,
      isSupportUser,
      showPendingBookingPages,
    } = props;
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.systemRoles = [...systemRoles];
    this.companies = [...companies];
    this.loginExpiresAt = loginExpiresAt;
    this.isSupportUser = isSupportUser;
    this.showPendingBookingPages = showPendingBookingPages;
  }

  id: string;
  firstName: string;
  lastName: string;
  email: string;
  systemRoles: Role[];
  companies: IdentityCompany[];
  loginExpiresAt: number;
  isSupportUser: boolean;
  showPendingBookingPages: boolean;

  updateName({ firstName, lastName }: Pick<Identity, "firstName" | "lastName">): void {
    this.firstName = firstName;
    this.lastName = lastName;
    this.save();
  }

  static load(): Identity {
    return SessionStorage.instance.get(Identity, Identity.storageKey, new Identity());
  }

  save(): void {
    SessionStorage.instance.set(Identity.storageKey, this);
  }

  remove(): void {
    SessionStorage.instance.remove(Identity.storageKey);
  }

  public get isAuthenticated(): boolean {
    return isAuthenticated(this);
  }

  public get isSystemAdmin(): boolean {
    return isSystemAdmin(this);
  }

  public getCompany(companyId: string): IdentityCompany {
    const found = this?.companies?.find((c) => c.companyId === companyId);
    return !found ? new IdentityCompany() : extend(new IdentityCompany(), found);
  }
}

export function isAuthenticated({ loginExpiresAt }: { loginExpiresAt: number }): boolean {
  return loginExpiresAt > Date.now();
}

export function isSystemAdmin({
  loginExpiresAt,
  systemRoles,
}: {
  loginExpiresAt: number;
  systemRoles: Role[];
}): boolean {
  return isAuthenticated({ loginExpiresAt }) && systemRoles.includes(Role.SYSTEM_ADMIN);
}
