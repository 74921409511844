import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Vuetify from "vuetify";
import countriesList from "./countriesPlugin";
import httpPlugin from "./http-plugin";
import publicPathPlugin from "./public-path-plugin";
import toastification from "./toastification-mixin";
import copyIdPlugin from "./copyIdPlugin";
import { messages } from "@gc/i18n";
// @ts-ignore
import VueMask from "v-mask";

import "./filters";
import "@/sass/index.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vuetify/dist/vuetify.min.css";
import ScopesIcon from "@gc/legacy-components/src/icon/scopes-icon.vue";
import ScoresIcon from "@gc/legacy-components/src/icon/scores-icon.vue";
import { i18n } from "@gc/i18n";

Vue.mixin(toastification);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(Vuetify);
Vue.use(countriesList);
Vue.use(httpPlugin);
Vue.use(publicPathPlugin);
Vue.use(copyIdPlugin);

// @ts-ignore
const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#009DC3",
        accent: "#424242",
        secondary: "#ff8f00",
        info: "#26a69a",
        warning: "#E8A100",
        error: "#DB0B43",
        success: "#69C786",
        grey: "#707070",
        brand: "#041036",
        "dark-blue-100": "#e6e7eb",
        "dark-blue-500": "#041036",
        "red-100": "#FBE7EC",
      },
      light: {
        primary: "#009DC3",
        accent: "#dddddd",
        secondary: "#ff8f00",
        info: "#dddddd",
        warning: "#E8A100",
        error: "#DB0B43",
        success: "#69C786",
        grey: "#707070",
        brand: "#041036",
        "dark-blue-100": "#e6e7eb",
        "dark-blue-500": "#041036",
        "red-100": "#FBE7EC",
      },
    },
  },
  icons: {
    values: {
      scopes: {
        component: ScopesIcon,
      },
      scores: {
        component: ScoresIcon,
      },
    },
  },
  lang: {
    locales: messages,
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
});

export { vuetify };
