import { LocalizedText } from "@gc/models";

export enum CompanySelection {
  ALL = "ALL",
  ALL_EXCEPT_SOME = "ALL_EXCEPT_SOME",
  ONLY_FOR_SOME = "ONLY_FOR_SOME",
}

export enum TagEntity {
  CO2_TYPE = "CO2_TYPE",
  FINANCIAL_MODEL = "FINANCIAL_MODEL",
  SUPPLIER = "SUPPLIER",
}

export interface TagDefinition {
  id: string;
  name: LocalizedText;
  description: Partial<LocalizedText>;
  managed: boolean; // If true only support users and admins should be able to assign tags
  companies: {
    selection: CompanySelection;
    list: {
      id: string;
      name: string;
    }[]; // list of companies. if "selection" field is 'ALL' then this array must be empty otherwise it must not be empty
  };
  entities: TagEntity[];
  validationRegex?: string;
}

export interface TagDefinitionInfo {
  id: string;
  name: LocalizedText;
  description: Partial<LocalizedText>;
  managed: boolean; // If true only support users and admins should be able to assign tags
  memberOfKeychain: boolean; // If true, it means this tag definition is part of a keychain in current company
  validationRegex?: string;
}
