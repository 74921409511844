/* eslint-disable */
export const messages = {
  system_admin_risk_analyses_configuration: "Configuration",
  system_admin_risk_analyses: "ESG Risk Analyses",
  system_admin_risk_analysis: "ESG Risk Analysis",
  system_admin_risk_analysis_invitation_message: "Invitation Message",
  system_admin_risk_analyses_create: "Create ESG Risk Analysis",
  system_admin_risk_analyses_edit: "Edit ESG Risk Analysis",
  system_admin_risk_analyses_evaluation_period: "Evaluation Period",
  system_admin_risk_analyses_deadline: "Deadline",
  system_admin_risk_assessment_template: "Survey Template (ESG Survey)",
  system_admin_risk_assessment_short_template: "Survey Template (Short Survey)",
};
