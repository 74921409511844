import { RouteConfig } from "vue-router";

export const esgRoutes: Array<RouteConfig> = [
  {
    path: `esg/risk-analyses`,
    component: () => import("@/views/esg/risk-analyses/index.vue"),
  },
  {
    path: `esg/risk-analyses/:id`,
    component: () => import("@/views/esg/risk-analyses/details/index.vue"),
  },
];
