export type On401 = (o?: { isPermaLink?: boolean }) => void;

export async function handleHttpError({
  error,
  on401,
  logInConsole,
  isPermaLink,
}: {
  error: any;
  on401?: On401;
  logInConsole?: boolean;
  isPermaLink?: boolean;
}): Promise<{
  errors: string[];
}> {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    if (error.response.status === 401 && on401) {
      on401({ isPermaLink });
    }
    const data = error.response.data;
    if (data instanceof Blob) {
      return JSON.parse(await data.text());
    }
    return error.response.data;
  }

  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  if (error.request) {
    if (logInConsole) {
      console.log(error.config);
      console.log(error.request);
    }
    return {
      errors: ["Error while connecting to server!"],
    };
  }

  // Something happened in setting up the request that triggered an Error
  if (logInConsole) {
    console.log(error.config);
    console.log("Error", error.message);
  }
  return {
    errors: ["Unknown error occurred!"],
  };
}
