/* eslint-disable */
export const messages = {
  customer_esg_general_information: "Allgemeine Informationen",
  customer_esg_rating_result: "Rating Resultat",
  customer_esg_rating_details: "Rating Details",
  customer_esg_scorecard_not_found: "Scorecard nicht gefunden!",
  customer_esg_total_score: "Gesamt",
  customer_esg_partner_ratings: "Partner-Ratings",
  customer_esg_ratings_current_state: "Aktueller Stand",
  customer_esg_ratings_progress_information: "Informationen zum Fortschritt",
  customer_esg_result: "Result",
  customer_esg_status_completed: "Abgeschlossen",
  customer_esg_status_in_review: "In Review",
  customer_esg_risk_analyses_overview: "Übersicht",
  customer_esg_risk_analyses: "ESG Risiko Analysen",
  customer_esg_risk_analyses_partners: "Partner",
  customer_esg_risk_analyses_partners_excel_export: "Als Excel exportieren",
  customer_esg_risk_analyses_partners_added: "Partner hinzugefügt",
  customer_esg_risk_analyses_dashboard: "Dashboard",
  customer_esg_risk_analyses_dashboard_invitation_accepted: "Angenommene Partneranfragen",
  customer_esg_risk_analyses_dashboard_open_invitations: "Offene Einladungen",
  customer_esg_risk_analyses_dashboard_started_surveys: "Gestartete Umfragen",
  customer_esg_risk_analyses_dashboard_completed_surveys: "Abgeschlossene Umfragen",
  customer_esg_risk_analyses_information: "Information",
  customer_esg_risk_analyses_evaluation_period: "Analyse-Zeitraum",
  customer_esg_risk_analyses_deadline: "Deadline",
  customer_esg_risk_analyses_deadline_defined: "Deadline konfiguriert",
  customer_esg_risk_analyses_invitation_message: "Einladungsnachricht",
  customer_esg_risk_analysis_invitation_message_placeholder:
    "Sehr geehrte Partner, wir führen eine ESG-Risikoanalyse durch. Bitte beantworten Sie alle Fragen bis zum {deadline}, ...",
  customer_esg_risk_analyses_invitation_message_set: "Einladungsnachricht konfiguriert",
  customer_esg_risk_analyses_topics: "Themen",
  customer_esg_risk_analyses_view_questions: "Fragen anzeigen",
  customer_esg_risk_analyses_table_info:
    "Führen Sie mit GLOBAL CLIMATE eine ESG-Risikoanalyse durch. Im ersten Schritt entwickeln wir gemeinsam mit Ihnen einen auf Ihre Bedürfnisse zugeschnittenen Fragebogen und KPIs. Über die GLOBAL SUITE können Sie Ihre Partner auffordern das ESG-Rating durchzuführen. Unsere Analysten prüfen die Antworten Ihrer Partner und bereiten die Ergebnisse für Sie auf.",
  customer_esg_risk_analyses_edit_title: "ESG Risiko Analyse bearbeiten",
  customer_esg_risk_analyses_delete_partner_message: `Möchten Sie wirklich den Partner '{0}' von der Risko Analyse entfernen?`,
  customer_esg_risk_analyses_add_partner_title: "Partner zur ESG-Risiko Analyse hinzufügen",
  customer_esg_risk_analyses_start_analysis_title: "ESG Risko Analyse starten",
  customer_esg_risk_analyses_add_partner_subtitle:
    "Partner, welche Sie zur Risiko Analyse hinzufügen, werden bei Start der Risiko Analyse per E-Mail dazu aufgefordert, die Fragen des ESG Ratings zu beantworten.",
  customer_esg_risk_analyses_partner_multiselect: "Partner Mehrfachauswahl",
  customer_esg_risk_analyses_distribution_of_grades: "Verteilung der Ratings",
  customer_esg_risk_analyses_results: "Ergebnisse",
  customer_esg_risk_analyses_progress_statistics: "Fortschritt",
  customer_esg_start_esg_survey_card_title: "Umfrage starten",
  customer_esg_start_new_esg_survey_alert: "Klicken Sie hier, wenn Sie das ESG Rating starten wollen",
  customer_esg_start_short_esg_survey_alert:
    "Klicken Sie hier, wenn Sie bereits ein ESG Rating Resultat zum Teilen haben",
  customer_esg_start_esg_survey_button: "Starte ESG Rating",
  customer_esg_start_short_esg_survey_button: "Teilen eines ESG Ratings",
  customer_esg_start_esg_survey: "ESG Rating starten (vollständige Umfrage)",
  customer_esg_start_esg_survey_text_1:
    "Bei Beantwortung der Umfrage wird der Fortschritt automatisch gespeichert. Bitte beantworten Sie alle notwendigen Fragen.",
  customer_esg_start_esg_survey_text_2:
    "Ihre Antworten werden von GLOBAL CLIMATE am Ende des Ratings analysiert. Das finale Ergebnis wird automatisch an Ihren Partner weitergeleitet.",
  customer_esg_start_esg_survey_text_3:
    "Bei Fragen ihrerseits können Sie über das Support Ticket System mit uns Kontakt aufnehmen.",
  customer_esg_start_esg_survey_text_4:
    "Wollen Sie die Umfrage starten? Ihr Partner <u>{partnerName}</u> wird automatisch darüber informiert, dass Sie an der Umfrage teilnehmen.",
  customer_esg_start_short_esg_survey_rating: "ESG Rating teilen (verkürzte Umfrage)",
  customer_esg_start_short_esg_survey_rating_text_1:
    "Wenn Sie bereits ein ESG Rating Resultat haben, welches Sie mit <u>{partnerName}</u> teilen wollen, dann können Sie hiermit einen verkürzten Fragebogen starten, über den Sie das Resultat hochladen können.",
  customer_esg_start_short_esg_survey_rating_text_2:
    "Nutzen Sie diese Option nur, wenn Sie bereits ein ESG Rating Resultat haben. Ansonsten starten Sie bitte ein neues ESG Rating über unsere GLOBAL SUITE.",
  customer_esg_start_short_esg_survey_rating_text_3:
    "Bei Beantwortung der Umfrage wird der Fortschritt automatisch gespeichert. Bitte beantworten Sie alle notwendigen Fragen.",
  customer_esg_start_short_esg_survey_rating_text_4:
    "Bei Fragen ihrerseits können Sie über das Support Ticket System mit uns Kontakt aufnehmen.",
  customer_esg_continue_esg_rating: "ESG Rating fortsetzen",
  customer_esg_view_survey: "Survey anzeigen",

  // Rating Request
  customer_esg_rating_request_details_status_1: "Bewertung initiiert",
  customer_esg_rating_request_details_status_2: "Umfrage läuft",
  customer_esg_rating_request_details_status_3: "Antworten in Review",
  customer_esg_rating_request_details_status_4: "Score mit Partner geteilt",
  customer_esg_rating_request_submit_survey: "Umfrage beenden",
  customer_esg_rating_request_submit_description:
    "Nach dem Beenden der Umfrage können Sie die Antworten nicht mehr ändern. Möchten Sie die Umfrage absenden?",
  customer_esg_rating_request_submitted_message:
    "Ihre Antworten wurden übermittelt. Sie erhalten eine E-Mail, sobald die Ergebnisse verfügbar sind.",
  customer_esg_rating_request_complete_survey_description_2:
    "Um den Fragebogen zu beenden, müssen alle Fragen ohne Validierungsfehler beantwortet sein.",
  customer_esg_rating_request_uploaded_documents: "Hochgeladene Dokumente",
  customer_esg_rating_request_number_of_questions: "Anzahl an Fragen",
  customer_esg_rating_request_number_of_invalid_answered_questions: "Anzahl an Fragen mit Validierungsfehler",
  customer_esg_rating_request_and_more: "Und {0} mehr...",
  customer_esg_rating_request_question: "Frage {0}",
  customer_esg_rating_request_validity: "Gültigkeit",
  customer_esg_rating_request_scorecard: "Scorecard",
  customer_esg_rating_request_state_invitation_pending: "Einladung ausstehend",
  customer_esg_rating_request_state_invitation_accepted: "Einladung angenommen",
  customer_esg_rating_request_state_in_progress: "ESG-Rating In Bearbeitung",
  customer_esg_rating_request_state_in_review: "ESG-Rating im Überblick",
  customer_esg_rating_request_state_completed: "ESG-Rating Abgeschlossen",
};
