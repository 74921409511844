export enum TicketState {
  WAITING_FOR_SUPPORT = "WAITING_FOR_SUPPORT",
  WAITING_FOR_CUSTOMER = "WAITING_FOR_CUSTOMER",
  CLOSED = "CLOSED",
}

export interface SupportUserInfo {
  id: string;
  firstName: string;
  lastName: string;
}

export interface CompanyInfo {
  id: string;
  name: string;
}

export interface TicketSearchModel {
  id: string;
  number: string;
  subject: string;
  state: TicketState;
  creator: SupportUserInfo;
  company: CompanyInfo;
  createdAt: number;
}
