/* eslint-disable */
export const messages = {
  customer_administration_users: "Nutzer",
  customer_administration_invite_user: "Nutzer einladen",
  customer_administration_invite: "Einladen",
  customer_administration_profile_status: "Profilstatus",
  customer_administration_basic_access: "Basic Access",
  customer_administration_administration_access: "Administration Access",
  customer_administration_sco2pes_access: "GLOBAL FOOTPRINT Access",
  customer_administration_compliance_access: "GLOBAL COMPLIANCE Access",
  customer_administration_completed: "Abgeschlossen",
  customer_administration_invited: "Eingeladen",
  customer_administration_identifier: "Kennung",
  customer_administration_functions: "Funktionen",
  customer_administration_view_dashboard: "Dashboard einsehen",
  customer_administration_role: "Rolle",
  customer_administration_deactivate: "Deaktivieren",
  customer_administration_deactivated: "Deaktiviert",
  customer_administration_deactivate_modal_title: "API Key Deaktivieren",
  customer_administration_deactivate_modal_description:
    "Die Deaktivierung eines API Keys kann nicht rückgängig gemacht werden.<br>Soll der API Key wirklich deaktiviert werden?",
  customer_administration_api_key_deactivated: "API-Schlüssel erfolgreich deaktiviert",
  customer_administration_new_api_key: "Neuer API Key",
  customer_administration_download_api_key: "API-Schlüssel herunterladen",
  customer_administration_new_api_key_description:
    "API Keys ermöglichen es externen Systemen, mit der GLOBAL CLIMATE API zu kommunizieren.<br>Soll ein neuer API Key erstellt werden?",
  customer_administration_download_api_key_description:
    "Ein neuer API Key wurde erstellt. <br> Laden Sie den API Key herunter und speichern Sie diesen auf sichere Weise. GLOBAL CLIMATE speichert eine verschlüsselte Version des Keys und ist nicht der Lage diesen zu entschlüsselen oder zu rekonstruieren.",
  customer_administration_invite_user_description:
    "Wenn Sie einen neuen Benutzer einladen, wird eine E-Mail mit einem Registrierungslink an den Benutzer gesendet. Geben Sie die E-Mail des Benutzers ein:",
  customer_administration_what_roles_should_get_assigned: "Welche Rollen sollen dem neuen Nutzer zugewiesen werden?",
  customer_administration_cant_edit_own_roles: "Nutzer dürfen nicht Ihre eigenen Rollen bearbeiten.",
  customer_administration_view_users_and_api_keys: "Anzeigen von Nutzern, API-Keys, Lizenzen und Buchungskreisen",
  customer_administration_view_and_create_support_tickets: "Anzeigen und Erstellen von Support-Tickets",
  customer_administration_view_invite_and_edit_users: "Anzeigen, Einladen und Editieren von Nutzern",
  customer_administration_view_csrd_tool: "CSRD-Tool anzeigen und beobachten",
  customer_administration_view_create_and_deactivate_api_keys: "Anzeigen, Erstellen und Deaktivieren von API-Keys",
  customer_administration_view_and_manage_licenses: "Anzeigen und Bestellen von Lizenzen",
  customer_administration_view_create_and_edit_company_code: "Anzeigen, Erstellen und Bearbeiten von Buchungskreisen",
  customer_administration_view_and_edit_co2_database: "Anzeigen und Bearbeiten der CO<sub>2</sub> Datenbank",
  customer_administration_view_and_edit_suppliers_and_transport_routes:
    "Anzeigen und Bearbeiten von Lieferanten und Logistikketten",
  customer_administration_view_and_edit_co2_bookings:
    "Anzeigen, Erstellen und Bearbeiten von Unit-Buchungen und Finanzbuchungen",
  customer_administration_view_and_edit_commuter_surveys: "Anzeigen, Erstellen und Bearbeiten von Pendler Umfragen",
  customer_administration_download_report: "Herunterladen von Reports",
  customer_administration_view_create_and_edit_assessments: "Anzeigen, Erstellen und Bearbeiten von ESG-Ratings",
  customer_administration_view_and_edit_partners: "Anzeigen und Bearbeiten von Partnern",
  customer_administration_share_assessments_with_partners: "Teilen von ESG-Rating Resultaten mit Partnern",
  customer_administration_company_details: "Unternehmens-Details",
  customer_administration_user: "Benutzer",
  customer_administration_license_required: "Lizenz notwendig",
  customer_administration_licenses: "Lizenzen",
  customer_administration_license_package: "Lizenz-Paket",
  customer_administration_license_list: "Lizenzliste",
  customer_administration_employees: "Mitarbeiter",
  customer_administration_buy_license: "Lizenz kaufen",
  customer_administration_license_status: "Lizenz-Status",
  customer_administration_license_info: "Lizenz-Informationen",
  customer_administration_license_costs: "Lizenzkosten",
  customer_administration_plus_vat: "zzgl. MwSt",
  customer_administration_ordered_by: "Bestellt von",
  customer_administration_invoice_status: "Status der Rechnung",
  customer_administration_ordered: "Bestellt",
  customer_administration_active: "Aktiv",
  customer_administration_void: "Void",
  customer_administration_download_invoice: "Rechnung herunterladen",
  customer_administration_open_payment_page: "Zahlungsseite öffnen",
  customer_administration_open: "Offen",
  customer_administration_paid: "Bezahlt",
  customer_administration_the_license_includes: "Die Lizenz beinhaltet",
  customer_administration_please_validate_billing_info: "Bitte überprüfen Sie die Zahlungsinformationen",
  customer_administration_small: "kleinen",
  customer_administration_medium: "mittleren",
  customer_administration_large: "großen",
  customer_administration_invoice_email: "Rechnungs-Email",
  customer_administration_tax_id: "Umsatzsteuer-ID",
  customer_administration_buy_license_description_1:
    "Wenn Sie fortfahren, wird eine Rechnung an die angegebene E-Mail-Adresse gesendet.",
  customer_administration_buy_license_description_2:
    "Sie können entweder über unseren Zahlungsdienstleister Stripe (Kreditkarte) zahlen, oder mittels Überweisung. Wenn Sie den Betrag direkt überweisen, wird die Lizenz freigeschalten, sobald Ihr Zahlungseingang verbucht wird.",
  customer_administration_thanks_for_ordering_license: "Vielen Dank für den Kauf einer 1 Jahres Lizenz!",
  customer_administration_we_will_send_you_the_invoice:
    "Wir schicken Ihnen die Rechnung an die angegebene Email ({0}), Sie können diese jedoch auch hier  herunterladen.",
  customer_administration_payment_description:
    "Bei Zahlung über unseren Zahlungsdienstleister Stripe können wir Ihre Lizenz sofort freischalten.",
  customer_administration_activating_license_after_payment:
    "Wenn Sie den Betrag direkt an die in der Rechnung angegebene Bankverbindung überweisen, wird die Lizenz freigeschalten, sobald Ihr Zahlungseingang verbucht wird.",
  customer_administration_pay_now: "Sofort Zahlen",
  customer_administration_next: "Weiter",
  customer_administration_requested_at: "Bestelldatum",
  customer_administration_validity_period: "Gültigkeits-Zeitraum",
  customer_administration_invalid_vat_tax_id: "Ungültige Umsatzsteuer-Identifikationsnummer",
  customer_administration_invitation_language: "Sprache Einladungsmail",
};
