export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  is2FAEnabled: boolean;
}

export enum AuthenticatorApp {
  GOOGLE_AUTHENTICATOR = "GOOGLE_AUTHENTICATOR",
  MICROSOFT_AUTHENTICATOR = "MICROSOFT_AUTHENTICATOR",
}
