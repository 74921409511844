export const messages = {
  // Common navigation items
  common_navigation_integration: "Integration",
  common_navigation_show_pending_booking_pages: "Show Pending Booking Pages",

  // Customer navigation items
  customer_navigation_global_footprint: "GLOBAL FOOTPRINT",
  customer_navigation_global_footprint_ccf: "CCF-Tool",
  customer_navigation_global_footprint_pcf: "PCF-Tool",
  customer_navigation_global_footprint_sbt: "SBT-Tool",
  customer_navigation_sco2pes_bookings: "Bookings",
  customer_navigation_sco2pes_base_data: "Base Data",
  customer_navigation_sco2pes_financial_models: "Financial Models",
  customer_navigation_sco2pes_pending_financial_bookings: "Pending Financial Bookings",
  customer_navigation_sco2pes_financial_bookings: "Financial Bookings",
  customer_navigation_sco2pes_unit_bookings: "Unit Bookings",
  customer_navigation_sco2pes_pending_unit_bookings: "Pending Unit Bookings",
  customer_navigation_sco2pes_finalised_reports: "Finalised Reports",
  customer_navigation_sco2pes_suppliers: "Suppliers",
  customer_navigation_sco2pes_company_codes: "Company Codes",
  customer_navigation_sco2pes_commuter_surveys: "Commuter Surveys",
  customer_navigation_sco2pes_co2_database: "CO<sub>2</sub> Database",
  customer_navigation_sco2pes_reporting: "Reporting",
  customer_navigation_sco2pes_company: "Company",
  customer_navigation_sco2pes_company_group: "Company Group",
  customer_navigation_sco2pes_co2_type_tags: "CO<sub>2</sub> Type Tags",
  customer_navigation_global_compliance: "GLOBAL COMPLIANCE",
  customer_navigation_esg_ratings: "Ratings",
  customer_navigation_esg_risk_analyses: "Risk Analyses",
  customer_navigation_esg_administration: "Administration",
  customer_navigation_esg_administration_users: "Users",
  customer_navigation_esg_administration_company: "Company",
  customer_navigation_esg_administration_licenses: "Licenses",
  customer_navigation_esg_support: "Support",
  customer_navigation_esg_profile: "Profile",
  customer_navigation_esg_account_selection: "Account Selection",
  customer_navigation_esg_logout: "Logout",
  customer_navigation_esg_esg: "ESG-Tool",
  customer_navigation_esg_lksg: "LkSG-Tool",
  customer_navigation_esg_csrd: "CSRD-Tool",
  customer_navigation_products: "Products",
  customer_navigation_other: "Other",
  customer_navigation_back_to_main_navigation: "Back to main navigation",
  customer_navigation_upload_section: "Upload Section",
  customer_navigation_navigation_surveys: "SURVEYS",
  customer_navigation_configuration: "Configuration",

  // System admin navigation items
  system_admin_navigation_co2_database: "CO<sub>2</sub> Database",
  system_admin_navigation_prices: "CO<sub>2</sub> Type Prices",
  system_admin_navigation_sco2pes: "GLOBAL FOOTPRINT",
  system_admin_navigation_compliance: "GLOBAL COMPLIANCE",
  system_admin_navigation_risk_analyses: "ESG Risk Analyses",
  system_admin_navigation_surveys: "Surveys",
  system_admin_navigation_survey_templates: "Survey Templates",
  system_admin_navigation_support: "Support",
  system_admin_navigation_users_and_companies: "Users & Companies",
  system_admin_navigation_users: "Users",
  system_admin_navigation_companies: "Companies",
  system_admin_navigation_company_groups: "Company Groups",
  system_admin_navigation_units: "Units",
  system_admin_navigation_distance_calc: "Distance Calculation",
};
