/* eslint-disable */
export const messages = {
  customer_support_support_tickets: "Support Tickets",
  customer_support_new_ticket_title: "New Support Ticket",
  customer_support_no_tickets_created: "No support tickets created",
  customer_support_search_by_search_term: "Free text search",
  customer_support_ticket_number: "Ticket Number",
  customer_support_subject: "Subject",
  customer_support_message: "Type your message",
  customer_support_user: "User",
  customer_support_company: "Company",
  customer_support_selected_file_size_must_be_less_than_1mb: "Selected file size must be less than 1 MB",
  customer_support_selected_file_size_must_be_less_than_5mb: "Selected file size must be less than 5 MB",
  customer_support_ticket_successfully_created: "Ticket successfully created",
  customer_support_ticket_successfully_closed: "Ticket successfully closed",
  customer_support_ticket_state_successfully_updated: "Ticket state successfully updated",
  customer_support_create_ticket_dialog_title: "Create Support Ticket",
  customer_support_new_ticket_description: "If you want to send us any issues or suggestions fill in the form. Our support team will answer you as soon as possible and inform you via email when your ticket has been answered.",
  customer_support_create_ticket_modal_text: "Our support will answer you as soon as possible. We will inform you via Email when your ticket has been answered.",
  customer_support_do_you_really_want_to_close_the_support_ticket: "Do you really want to close the support ticket?",
  customer_support_attachment_file: "Attachment File",
  customer_support_closed_the_ticket_on: "closed the ticket on",
  customer_support_send_message: "Send Message",
  customer_support_close_ticket: "Close ticket",
  customer_support_show_all: "Show All",
  customer_support_waiting_for_support: "Waiting for support",
  customer_support_waiting_for_customer: "Waiting for customer",
  customer_support_closed: "Closed",
  customer_support_info: "If you have any questions, you can always submit a support ticket. We will be happy to process this for you during our opening hours from Monday to Friday between 9:00 a.m. and 5:00 p.m.",
  customer_support_close_ticket_description: "If you have no further enquiries, you can close the ticket.",
  customer_support_send_only: "Send only",
  customer_support_send_only_desc: "Send message without changing status",
  customer_support_send_and_update: "Send and update status",
  customer_support_send_and_update_desc: "Send message and update status automatically to \"wating for customer\"",
  customer_support_send_and_close: "Send and close ticket",
  customer_support_send_and_close_desc: "Send message and close ticket",
  customer_support_close_ticket_desc: "Close ticket without sending a message to customer",
};
