export class FormDataExtensions {
  /**
   * put anything inside a FormData object
   * @param formDataObject
   * @param value
   */
  static put(formDataObject: FormData, value: any): FormData {
    return objectToFormData(
      value,
      {
        indices: true,
        nulls: true,
      },
      formDataObject,
      ""
    );
  }

  /**
   * Prints the given form data object in console
   * @param formDataObject
   * @returns {void}
   */
  static print(formDataObject: FormData): void {
    if (formDataObject.entries === undefined || formDataObject.entries === null) {
      console.log("formdata.entries not supported in this browser: " + navigator.userAgent);
      return;
    }
    // @ts-ignore
    for (const pair of formDataObject.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
  }
}

function toEnglishNumbers(str: string) {
  // @ts-ignore
  return str.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c: string) {
    return c.charCodeAt(0) & 0xf;
  });
}

function isUndefined(value: any) {
  return value === undefined;
}

function isNull(value: any) {
  return value === null;
}

function isObject(value: any) {
  return value === Object(value);
}

function isArray(value: any) {
  return Array.isArray(value);
}

function isDate(value: any) {
  return value instanceof Date;
}

function isBlob(value: any) {
  return value && typeof value.size === "number" && typeof value.type === "string" && typeof value.slice === "function";
}

function isFile(value: any) {
  return (
    isBlob(value) &&
    (typeof value.lastModifiedDate === "object" || typeof value.lastModified === "number") &&
    typeof value.name === "string"
  );
}

function isFormData(value: any) {
  return value instanceof FormData;
}

function isString(value: any) {
  return typeof value === "string" || value instanceof String;
}

/**
 *
 * @param obj
 * @param cfg
 * @param fd
 * @param pre
 * @returns {FormData}
 */
function objectToFormData(obj: any, cfg: any, fd: any, pre: any): FormData {
  if (isFormData(cfg)) {
    pre = fd;
    fd = cfg;
    cfg = null;
  }

  cfg = cfg || {};
  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;
  cfg.nulls = isUndefined(cfg.nulls) ? true : cfg.nulls;
  fd = fd || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } else if (isNull(obj)) {
    if (cfg.nulls) {
      fd.append(pre, "");
    }
  } else if (isArray(obj)) {
    if (!obj.length) {
      const key = pre + "[]";
      fd.append(key, "");
    } else {
      obj.forEach(function (value: any) {
        objectToFormData(value, cfg, fd, pre);
      });
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach(function (prop) {
      const value = obj[prop];
      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf("[]") === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }
      const key = pre ? pre + "." + prop : prop;
      objectToFormData(value, cfg, fd, key);
    });
  } else {
    //to english numbers if obj is string
    if (isString(obj)) {
      fd.append(pre, toEnglishNumbers(obj));
    } else {
      fd.append(pre, obj);
    }
  }

  return fd;
}
