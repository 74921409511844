/* eslint-disable */
export const messages = {
  customer_faq_title: "Sustainability FAQ and Glossary",
  customer_faq_subtitle: "Find the answer for your needs",
  customer_faq_cs_title: "Can't find your answer?",
  customer_faq_cs_button: "Contact support",
  customer_faq_popular_topics: "Popular Topics",
  customer_faq_topic_not_available: "No matching topic found",
  customer_faq_topic_not_available_description: "No matching topic found, please contact our support for assistance",
  customer_faq_topics: "Topics",
  customer_faq_keyword_search: "FAQ keyword search",
  customer_faq_q_1: "ESG",
  customer_faq_a_1: "ESG (Environment, Sovial and Governance) is used as an extended term for CSR (Corporate Social Responsibility) and is considered a criterion for the contribution of business to sustainable development. for many sustainability standards the ESG criteria form the basis",
  customer_faq_q_2: "CO<sub>2</sub> handprint",
  customer_faq_a_2: "The existing footprint approach focuses on negative ecological impacts of individuals, organizations or countries. The handprint, on the other hand, is intended to capture, measure and evaluate the social added value or positive sustainability impacts of products, and to include the social and economic dimensions in the analysis.",
  customer_faq_q_3: "Scope 1, Scope 2, Scope 3",
  customer_faq_a_3: "Classification of emissions into direct and indirect emissions.<br><br>Scope 1: Direct emissions caused by the combustion of fossil fuels in production or by processes within the company itself.<br><br>Scope 2: Indirect emissions caused by the purchase of energy or electricity.<br><br>Scope 3: Indirect emissions caused by processes that take place outside the company but are related to the production and operation of the company. Compared to Scope 1 and 2, SE emissions are often more difficult to measure and control.",
  customer_faq_q_4: "Product Carbon Footprint",
  customer_faq_a_4: "PCF (Product Carbon Footprint) stands for the greenhouse gas emissions caused by a product over its entire life cycle. Depending on the balance sheet boundary, a distinction is made between a cradle-to-grave and a cradle-to-gate approach. The Product Carbon Footprint is not a direct derivative of the Corporate Carbon Footprint (CCF divided by produced quantity does not equal PCF).",
  customer_faq_q_5: "Greenhouse gas emissions",
  customer_faq_a_5: "Greenhouse gases are those gases in the atmosphere with an influence on the earth's energy balance. They thus cause the so-called greenhouse gas effect. The best-known greenhouse gases, carbon dioxide (CO<sub>2</sub>), methane and nitrous oxide, are naturally present in the atmosphere in low concentrations. However, due to various man-made sources, the proportion has increased significantly since the beginning of the last century",
  customer_faq_q_6: "Life Cycle Analysis",
  customer_faq_a_6: "LCA (Life Cycle Analysis) stands for the life cycle analysis or life cycle assessment of a specific product based on its total environmental impact. In contrast to the Product Carbon Footprint, the environmental impacts are divided into so-called impact categories: » Climate change » Ozone depletion » Acidification » Eutrophication » Resource depletion » Human toxicity » Ecotoxicity » Land use » Water consumption » Ionizing radiation » Particulate matter emissions The calculation of an LCA is very time-consuming. An LCA can be used internationally as an EPD (Environmental Product Declaration) through third party verification.",
  customer_faq_q_7: "CO<sub>2</sub> equivalents",
  customer_faq_a_7: "CO<sub>2</sub>-eq are a unit of measurement for standardizing the climate impact of different greenhouse gases. To make the effect of different greenhouse gases comparable, the so-called \"Global Warming Potential\" is defined. This index expresses the warming effect of a certain amount of a greenhouse gas over a defined period of time (usually 100 years) compared to that of CO<sub>2</sub>. For example, methane has a 28x greater climate impact than CO<sub>2</sub>, but remains in the atmosphere for less time. The climate impact of nitrous oxide even exceeds that of CO<sub>2</sub> by almost 300 times.",
  customer_faq_q_8: "Greenhouse gas balancing",
  customer_faq_a_8: "Greenhouse gas balancing is the allocation of the greenhouse gases produced according to certain classification criteria. Through standardizations of accounting such as the Greenhouse Gas Protocol, greenhouse gas balances can be compared between companies.",
  customer_faq_q_9: "Corporate Carbon Footprint",
  customer_faq_a_9: "CCF (Corporate Carbon Footprint) stands for the total greenhouse gas emissions caused by a company over a certain period of time, expressed in metric tons of CO<sub>2</sub>-eq. The definition of the balance limits determines which emissions are included or not included in the greenhouse gas balance. The classification is made into direct (Scope 1) and indirect (Scope 2 and Scope 3) emissions.",
  customer_faq_q_10: "Science Based Targets Initiative",
  customer_faq_a_10: "The Science Based Targets initiative was launched in 2015 to help companies set emissions reduction targets in line with climate science and the goals of the Paris Agreement",
  customer_faq_q_11: "EU Green Deal",
  customer_faq_a_11: "As part of the European Green Deal, the EU has set itself the binding target of achieving climate neutrality by 2050 through the European Climate Change Act. The Green Deal supports this shift toward a just and prosperous society with a modern and competitive economy. The legal framework for the Green Deal is provided by the \"Fit for 55\" package",
  customer_faq_q_12: "Corporate Sustainability Reporting",
  customer_faq_a_12: "The Corporate Sustainability Reporting Directive (CSRD) adopted by the EU Parliament in November 2022 will profoundly change the scope and nature of corporate sustainability reporting. The CSRD significantly expands existing rules on non-financial reporting. All companies listed on an EU-regulated market (with the exception of micro-entities) are covered by the new reporting requirements. In addition, all non-capital market-oriented companies are covered by the CSRD if they meet two of the following three criteria: \" Balance sheet total > 20 million euros \" Net sales > 40 million euros \" Number of employees > 250 It is estimated that this affects around 50,000 companies in the EU",
  customer_faq_q_13: "EU ETS?",
  customer_faq_a_13: "The European Emissions Trading Scheme (EU-ETS) was introduced in 2005 to implement the Kyoto international climate protection agreement and is the central European climate protection instrument. In addition to the 27 EU member states, Norway, Iceland and Liechtenstein have also joined the EU ETS (EU 30). The United Kingdom participated in the EU ETS until 31.12.2020. Since 01.01.2021, a national emissions trading system has been in force there. The EU ETS covers the emissions of around 10,000 plants in the energy sector and energy-intensive industry across Europe. Together, these facilities cause around 36% of greenhouse gas emissions in Europe. Since 2012, intra-European air traffic has also been included in the EU ETS.",
  customer_faq_q_14: "Green Claim Directive",
  customer_faq_a_14: "The directive aims to eliminate misleading environmental claims in EU markets and address greenwashing concerns by setting the EU's first detailed rules on how companies should market their environmental impacts and performance. The new requirements will necessitate significant changes to the way many companies currently demonstrate and communicate their environmental claims and manage information about their environmental performance. Once the directive enters into force, member states will have 18 months to transpose it into national law",
  customer_faq_q_15: "EU taxonomy",
  customer_faq_a_15: "In order to achieve the EU's climate and energy targets, investments must be channeled into sustainable projects and activities. A clear definition of the term \"sustainable\" is therefore required. The EU Taxonomy Regulation was created for this purpose. It is the common classification system for sustainable economic activities",
  customer_faq_q_16: "EU Fit for 55",
  customer_faq_a_16: "The Fit for 55 package is a comprehensive legislative package, which aims to enable the Union to achieve the climate target of at least 55% emission reduction from 1990 levels by 2030 and climate neutrality by 2050. The CSRD, CSDDD, CBAM and others are part of this package.",
  customer_faq_q_17: "EU climate law",
  customer_faq_a_17: "The Climate Change Act sets the EU's long-term climate target of climate neutrality by 2050. As an interim target, net greenhouse gas emissions are to be reduced by 55% by 2030. The EU and its member states must take action to realize these climate targets and the EU's contribution to the Paris Agreement",
  customer_faq_q_18: "CSDDD",
  customer_faq_a_18: "The Corporate Sustainability Due Diligence Directive aims to prevent or mitigate negative impacts on human rights and the environment in corporate value chains. This is intended to contribute to the transition to a sustainable economy, particularly under the guiding principle of the Green Deal. Companies should identify, prevent and be accountable for the negative impacts caused by their operations. Appropriate corporate governance and management systems must be established to fulfill this purpose. Improved remedies shall be established for victims of negative impacts on human rights and the environment",
  customer_faq_q_19: "CBAM",
  customer_faq_a_19: "CBAM is a climate protection instrument of the EU and is the counterpart to the EU Emissions Trading Scheme (EU ETS). The first reporting obligation under the CBAM Regulation starts in October 2023. From 2026, CBAM certificates must be purchased for the import of certain goods whose production in third countries emitted greenhouse gases. The quantity of certificates to be purchased depends on the emissions generated during production. The price per certificate is based on the price of the EU ETS certificates (EUA auction price).",
  customer_faq_q_20: "Corporate Carbon Footprint (CO<sub>2</sub> balance)",
  customer_faq_a_20: "The corporate carbon footprint measures the total greenhouse gas emissions caused directly and indirectly by a person, organization, event or product.",
  customer_faq_q_21: "Greenhouse Gas protocol (GHG Protocol)",
  customer_faq_a_21: "The GHG Protocol is an internationally recognized standard for accounting for corporate emissions.",
  customer_faq_q_22: "Principles Corporate Carbon Footprint (CO<sub>2</sub> balance)",
  customer_faq_a_22: "Relevance:<br>All major sources of emissions must be considered when preparing a carbon footprint for a company.The report should present a realistic picture inside and outside the company.<br><br>Completeness:<br>All relevant emission sources within the system boundaries must be considered.<br><br>Transparency:<br>The results should be presented in a transparent and clearly understandable manner.<br><br>Consistency:<br>For comparability of results over the multi-year period, accounting methodologies and system boundaries shall be maintained. Changes in methodology or system boundaries must be justified and reported.<br><br>Accuracy:<br>Results should be presented free of bias and uncertainty to the extent possible.",
  customer_faq_q_23: "Why carbon footprint?",
  customer_faq_a_23: "The CO<sub>2</sub> balance sheet serves to identify the largest sources of emissions within the company and along the upstream and downstream stages of the value chain. It thus forms the basis for the development of a climate protection strategy in which targets, measures and responsibilities for reducing greenhouse gas emissions are defined. In subsequent years, it is used to check whether targets have been achieved, in which areas progress has been made, and in which areas there is a need for action to reduce CO<sub>2</sub>.",
  customer_faq_q_24: "Why system boundaries?",
  customer_faq_a_24: "A carbon footprint requires a clear definition of the system boundaries to which the carbon footprint relates. This includes organizational and operational system boundaries.",
  customer_faq_q_25: "Organizational system boundaries",
  customer_faq_a_25: "They describe the organizational unit and the period to which the CO<sub>2</sub> balance relates. The system boundaries can be defined according to operational or financial control or according to the capital share. The organizational system boundary was defined as the Klett Group's scope of consolidation under commercial law.",
  customer_faq_q_26: "Operational system boundaries",
  customer_faq_a_26: "describe the emission sources that are taken into account within the organizational boundaries. To delineate different emission sources, the GHG Protocol distinguishes between three categories (\"GLOBAL FOOTPRINT\").",
  customer_faq_q_27: "Scope 1 emissions",
  customer_faq_a_27: "SCOPE 1 shows all CO<sub>2</sub> emissions that can be directly controlled by the reporting company (direct CO<sub>2</sub> emissions). These include the combustion of fossil fuels (mobile and stationary), CO<sub>2</sub> emissions from chemical and physical processes, and refrigerant leaks from air conditioning systems.",
  customer_faq_q_28: "Scope 2 emissions",
  customer_faq_a_28: "In SCOPE 2, indirect CO<sub>2</sub> emissions caused by the combustion of fossil fuels during the production of electricity, heating, cooling and steam at external energy suppliers are reported. Reporting in a separate category avoids double counting when comparing CO<sub>2</sub> emissions from different companies.",
  customer_faq_q_29: "Scope 3 emissions",
  customer_faq_a_29: "All other CO<sub>2</sub> emissions that are not under direct corporate control are reported in SCOPE 3 (other indirect CO<sub>2</sub> emissions). These include, for example, CO<sub>2</sub> emissions associated with products and services used or processed by the reporting company. In addition, there are CO<sub>2</sub> emissions associated with the use of sold products and services if direct CO<sub>2</sub> emissions are caused in the process.",
  customer_faq_q_30: "Data acquisition and calculation",
  customer_faq_a_30: "The calculation of CO<sub>2</sub> emissions is based on the company's financial data. This data was taken directly from the corporate accounting and used as primary activity data for the CO<sub>2</sub> calculation. Furthermore, internal records and calculations of the customer were included in the calculations as secondary data and after verification and plausibility check by GLOBAL CLIMATE.",
  customer_faq_q_31: "Greenhouse gases",
  customer_faq_a_31: "The metric used for greenhouse gas accounting is the CO<sub>2</sub> equivalent (also CO<sub>2</sub>e). This is used to convert not only the climate-damaging gas CO<sub>2</sub>, but also the emissions of other climate-damaging greenhouse gases based on their GWP (Global Warming Potential) into the equivalent amount of CO<sub>2</sub> and balance them.<br><br><ul><li>Methane (CH4),</li><li>Nitrous oxide (N2O),</li><li>Sulfur hexafluoride (SF6).</li><li>Hydrofluorocarbons (HFC and HFC).</li></ul>",
  customer_faq_q_32: "GWP value",
  customer_faq_a_32: "Global Warming Potential - Global warming potential.",
  customer_faq_q_33: "Whereby GLOBAL CLIMATE supports",
  customer_faq_a_33: "<ul><li>Preparation of a complete CO<sub>2</sub> balance (greenhouse gas balance)</li><li>Identification of CO<sub>2</sub> hotspots</li><li>Analysis and the CO<sub>2</sub> emissions</li><li>Targeted control of CO<sub>2</sub> reduction measures as well as in a</li><li>Sustainability monitoring of the supply chains</li></ul>",
  customer_faq_q_34: "What do I get in the end?",
  customer_faq_a_34: "<ul><li>Audit-proof CO<sub>2</sub> balance</li><li>GLOBAL CLIMATE seal of approval for the respective reporting year</li></ul>"
};
