/* eslint-disable */
export const messages = {
  customer_welcome_news: "Neuigkeiten",
  customer_welcome_news_title_14_11_2024: "Freemium-Version des CSRD-Moduls",
  customer_welcome_news_content_14_11_2024: `GLOBAL CLIMATE freut sich, die Freemium-Version des CSRD-Moduls vorzustellen: <ul class="ma-1"> <li>Interessierte Unternehmen haben nun die Möglichkeit, einen Überblick über die Kernfunktionen der doppelten Wesentlichkeitsanalyse zu erhalten</li> <li>Erstellen Sie IROs (Auswirkungen, Risiken und Chancen)</li> <li>Laden Sie einen Stakeholder ein, um den Stakeholder-Prozess zu verstehen</li> </ul>Wenn auch Sie vor der Herausforderung stehen, Ihre CSRD-Anforderungen zu erfüllen, zögern Sie bitte nicht, uns zu kontaktieren.`,
  customer_welcome_news_title_09_11_2024: "Neue Funktionen der GLOBAL SUITE",
  customer_welcome_news_content_09_11_2024: `GLOBAL CLIMATE freut sich, die folgenden neuen Funktionen des CCF-Moduls zu präsentieren: <ul class="ma-1"> <li>Erweiterung des Versionsmechanismus durch Einführung einer Funktionalität für finalisierte Berichte</li> <li>Neuer Rundungsmechanismus in Finanzbuchungen</li> <li>Asynchrone Berechnung von Emissionen führt zu signifikanter Performance Verbesserung für unsere Kunden mit großen Datenmengen</li> </ul>`,
  customer_welcome_products: "GLOBAL CLIMATE Produkte",
  customer_welcome_esg_benefit_1:
    "Professionelle ESG-Bewertung Ihres Unternehmens durch Analysten von GLOBAL CLIMATE",
  customer_welcome_esg_benefit_2: "Überwachung der Lieferanten Compliance",
  customer_welcome_esg_benefit_3: "Automatisierte globale THG-Ermittlung Ihrer Lieferanten",
  customer_welcome_esg_benefit_4:
    "ESG-Scorecard und Nachhaltigkeitszertifikat zum Teilen und kommunizieren mit Ihren Geschäftspartnern",
  customer_welcome_esg_benefit_5: "Unbegrenzte Lieferantenbewertungen weltweit in verschiedenen Sprachen",
  customer_welcome_esg_benefit_6: "Umfangreiche KPI`s nach GRI zur Nachhaltigkeitsberichterstattung",
  customer_welcome_esg_benefit_7: "Evaluierung der eigenen CSR",
  customer_welcome_benefit_when_using_sco2pes: "GLOBAL FOOTPRINT CO<sub>2</sub> Accounting",
  customer_welcome_sco2pes_benefit_1:
    "Prozessintegrierte und finanzdatenbasierte CO<sub>2</sub> Bilanzierung nach dem GHG-Protokoll",
  customer_welcome_sco2pes_benefit_2:
    "Verwendung von Echtwerten Ihres Unternehmens anstelle von Schätzungen und Statistiken",
  customer_welcome_sco2pes_benefit_3: "Ergebnisse in Echtzeit",
  customer_welcome_sco2pes_benefit_4: "Vollständige CO<sub>2</sub>-Bilanz (Scope 1, Scope 2 und Scope 3)",
  customer_welcome_sco2pes_benefit_5: "Wissenschaftlich geprüfte CO<sub>2</sub>-Datenbank",
  customer_welcome_sco2pes_benefit_6: "Austomatisierte Verbuchung von CO<sub>2</sub>-relevanten Geschäftsvorfällen",
  customer_welcome_sco2pes_benefit_7: "Schnittstelle zu SAP, Microsoft Dynamics uns DATEV",
  customer_welcome_dialog_title: "Willkommen auf der GLOBAL SUITE",
  customer_welcome_dialog_description:
    "Sie haben sich erfolgreich auf der GLOBAL SUITE registriert.<br />Schauen Sie sich gerne auf der Plattform um. Ihre offenen Umfragen finden Sie im Navigationsmenü unter Umfragen.",
  customer_freemium_dialog_title: "Willkommen beim<br />CSRD-Software-Tasting",
  customer_freemium_dialog_description:
    'Schön, dass Sie unser CSRD-Tool testen und die Gelegenheit nutzen, unsere Kernfunktionalitäten („Themenauswahl“, „Stakeholder Feedback“ und „IRO Defintion“) der Doppelten Wesentlichkeitsanalyse kennenzulernen. So bekommen Sie einen kleinen Vorgeschmack darauf, was in unser GLOBAL SUITE möglich ist.<br /><strong>Diese Freemium-Lizenz ist gültig bis {0}</strong> (Testzeitraum 6 Wochen).<br /><br /><strong>So geht’s:</strong> Klicken Sie in der linken Navigationsbar auf <strong>GLOBAL COMPLIANCE</strong> und wählen Sie dort das CSRD-Tool aus. Jetzt können Sie die drei Kernfunktionalitäten testen. Viel Spaß beim Ausprobieren der Software!<br /><br />Wenn Sie Fragen, Anregungen oder Wünsche haben, dann kontaktieren Sie uns gerne. Unser Sales-Team steht Ihnen jederzeit beratend zur Seite.',
  customer_freemium_dialog_button_demo: "Demo buchen",
  customer_welcome_news_update: "Neues Update",
  customer_welcome_banner_title_1: "Willkommen in der",
  customer_welcome_banner_title_2: "GLOBAL SUITE",
  customer_welcome_banner_title_3:
    "Die All-In-One Softwarelösung für eine <b>effiziente, gesetzeskonforme und prüfungssichere</b> Nachhaltigkeitsberichterstattung",
  customer_welcome_survey_title_1: "Sie haben eine Umfrage auszufüllen",
  customer_welcome_survey_subtitle_plural: "Sie haben <strong>{0}</strong> offene Umfragen",
  customer_welcome_survey_subtitle_singular: "Sie haben <strong>{0}</strong> offene Umfrage",
  customer_welcome_update_title: "Ein neues Update wurde veröffentlicht",
  customer_welcome_update_subtitle: "Verbesserungen der GLOBAL SUITE",
  customer_welcome_product_title: "Unsere Lösung",
  customer_welcome_product_subtitle:
    "Unsere Software bildet die gesetzlichen ESG-Vorgaben von CSRD und des LkSG vollständig ab und bringt maximale Effizienz in die Erstellung von CO<sub>2</sub>-Bilanzen und -Berichten.",
  customer_welcome_product_scopes_description:
    "Vollautomatisierte Erstellung von CO<sub>2</sub>-Bilanzen in Scope 1, 2, 3 und Berechnung von Product Carbon Footprints entlang des gesamten Produktlebenszyklus.",
  customer_welcome_product_scores_description:
    "Gesetzliche Berichtspflichten vollständig, rechtskonform und prüfungssicher erfüllen.",
  customer_welcome_ccf_product_link: "CCF-Tool",
  customer_welcome_pcf_product_link: "PCF-Tool",
  customer_welcome_sbt_product_link: "SBT-Tool",
  customer_welcome_esg_product_link: "CSRD-Tool",
  customer_welcome_lksg_product_link: "LkSG-Tool",
  customer_welcome_csrd_product_link: "ESG-Tool",
  customer_welcome_sales_title: "Kontaktieren Sie das Vertriebsteam für weitere Informationen",
  customer_welcome_sales_subtitle:
    "Wir freuen uns darauf, von Ihnen zu hören. Kontaktieren Sie uns einfach per Telefon, E-Mail oder Kontaktformular oder buchen Sie direkt eine Demo. Wir sind bereit! ",
  customer_welcome_sales_cta_1: "Kontakt Vertrieb",
  customer_welcome_sales_cta_2: "Jetzt eine Demo buchen",
  customer_welcome_sales_dialog_title: "Wir freuen uns, von Ihnen zu hören",
  customer_welcome_sales_dialog_description:
    "Rufen Sie uns einfach an oder kontaktieren Sie uns per E-Mail. Wir sind da und freuen uns auf ein erstes Treffen, um Sie kennenzulernen!",
  customer_welcome_sales_support_ticket: "Support Ticket",
};
