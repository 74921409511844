import { Lang } from "./lang";
import { detectBrowserLanguage, LocalStorage } from "@gc/helpers";

export class Settings {
  private static readonly storageKey: string = "settings";
  lang: Lang = detectBrowserLanguage();

  updateLang(lang: Lang): void {
    this.lang = lang;
    this.save();
  }

  static load(): Settings {
    return LocalStorage.instance.get(Settings, Settings.storageKey, new Settings());
  }

  private save(): void {
    LocalStorage.instance.set(Settings.storageKey, this);
  }
}
