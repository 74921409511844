import { StringHelper } from "@gc/helpers/src/string-helper";

export type SelectedCompanyMethodArgs = {
  selectedCompanyId?: string;
  log?: boolean;
};
export type SelectedCompanyMethod = (args?: SelectedCompanyMethodArgs) => string;

export class SelectedCompanyUrl {
  static readonly PREFIX = "realm";

  public static extractId(log?: boolean): string | undefined {
    // Taking the company id from the router doesn't work because on initial page load the router
    // returns undefined (Router.currentRoute.params.selectedCompanyId) which would always open the
    // account selection page.
    const parts = window.location.pathname?.split("/");
    if (log) console.log(parts);
    if (parts[1] === SelectedCompanyUrl.PREFIX && parts.length > 2) {
      const companyId = parts[2];
      if (log) console.log(companyId, StringHelper.instance.isUuid(companyId));
      if (companyId && StringHelper.instance.isUuid(companyId)) return companyId;
    }
  }
}
