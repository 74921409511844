import { extend, isNil } from "lodash";

abstract class BaseStorage {
  abstract getStorage(): Storage;

  public get<T>(type: new () => T, key: string, defaultValue: T): T {
    const json = this.getStorage().getItem(key);
    if (isNil(json) || json === "") {
      return this.set(key, defaultValue);
    }
    const x = JSON.parse(json);
    return extend(new type(), x) as T;
  }

  public set<T>(key: string, value: T): T {
    const json = isNil(value) ? "" : JSON.stringify(value);
    this.getStorage().setItem(key, json);
    return value;
  }

  public remove(key: string): void {
    this.getStorage().removeItem(key);
  }

  public has(key: string): boolean {
    return !!this.getStorage().getItem(key);
  }
}

export class LocalStorage extends BaseStorage {
  static instance: LocalStorage = new LocalStorage();
  getStorage(): Storage {
    return localStorage;
  }
}

export class SessionStorage extends BaseStorage {
  static instance: SessionStorage = new SessionStorage();
  getStorage(): Storage {
    return sessionStorage;
  }
}
