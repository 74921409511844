export interface Co2TypeVersion {
  id?: string;
  validFrom: string;
  validUntil: string;
  locked: boolean;
  co2Factor: number;
  kgEquivalent: number;
  originDatabase: string;
  originDatabaseInfo: string;
}

export enum QuantityType {
  DEFAULT = "DEFAULT",
  DISTANCE_ROAD = "DISTANCE_ROAD",
  DISTANCE_RAIL = "DISTANCE_RAIL",
  DISTANCE_SEA = "DISTANCE_SEA",
  DISTANCE_AIR = "DISTANCE_AIR",
}

export enum Co2TypeActivationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export enum Co2CategoryActivationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface ScopeInfo {
  id: string;
  name: string;
  description: string;
}

export interface UICategoryInfo {
  id: string;
  name: string;
}

export interface LocalizedText {
  de: string;
  en: string;
}

export interface Co2Category {
  id: string;
  name: LocalizedText;
  scope: ScopeInfo;
  createdAt: number;
  createdBy?: string;
  uiCategory: UICategoryInfo;
  activationStatus: Co2CategoryActivationStatus;
}

export interface Co2CategorySearchModel {
  id: string;
  name: string;
  scope: string;
  createdAt: number;
  createdBy?: string;
  activationStatus: Co2CategoryActivationStatus;
}

export interface CategoryInfo {
  id: string;
  name: string;
  activationStatus: Co2CategoryActivationStatus;
}

export interface Co2Type {
  id: string;
  name: LocalizedText;
  companyName: string;
  companyId: string;
  geography: string;
  quantityType: QuantityType;
  scope: ScopeInfo;
  category: CategoryInfo;
  unit: {
    id: string;
    name: string;
    description?: string;
    symbols: string[];
  };
  versions: Co2TypeVersion[];
  activationStatus: Co2TypeActivationStatus;
}

export interface Co2TypeSearch {
  id: string;
  name: string;
  categoryId: string;
  categoryName: string;
  categoryActivationStatus: Co2CategoryActivationStatus;
  companyId: string;
  companyName: string;
  scope: string;
  unit: string;
  versionsCount: number;
  currentCo2Factor: number;
  currentKgEquivalent?: number;
  createdAt: number;
  createdBy: string;
  activationStatus: Co2TypeActivationStatus;
  quantityType?: QuantityType;
  geography?: string;
  versions?: Co2TypeVersion[];
  externalIds?: string[];
}

export interface Co2TypeUsage {
  company: {
    id: string;
    name: string;
  };
  taggedCount: number; // number of times the co2 type is tagged
  unitBookingCount: number; // number of unit bookings connected to the co2 type
  pendingUnitBookingCount: number;
  supplierCount: number; // number of suppliers having at least 1 transport route which uses the co2 type
  financialModelCount: number; // number of financial models having at least 1 financial model item which uses the co2 type
  commuterSurveyCount: number; // number of commuter surveys connected to the co2 type
}
