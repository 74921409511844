import { CustomerURLs } from "./src/customer";
import { AuthURLs } from "./src/public/auth-urls";
import { IntegrationURLs } from "./src/public/integration-urls";
import { AdminURLs } from "./src/admin";

export * from "./src/selected-company";

export { InvalidTokenComingFromParameter } from "./src/public/auth-urls";

class AppURLs {
  home() {
    return "/";
  }

  auth = new AuthURLs();
  integration = new IntegrationURLs();
  admin = new AdminURLs();
  customer = new CustomerURLs();
}

const URLs = new AppURLs();

export { URLs };
