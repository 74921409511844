import { SelectedCompanyMethod } from "../..";

export class CustomerSupportURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}
  home() {
    return `${this.customerHome()}/support`;
  }
  supportDetails(id: string) {
    return `${this.home()}/${id}`;
  }
}
