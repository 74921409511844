export enum CurrencyCode {
  EUR = "EUR",
  USD = "USD",
  CHF = "CHF",
  GBP = "GBP",
  PLN = "PLN",
  HUF = "HUF",
  CNY = "CNY",
  MXN = "MXN",
  INR = "INR",
}
