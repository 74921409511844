import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { guards } from "./guards";
import { customerRoutes } from "./routes";
import NotFound from "@/views/404.vue";
import { SelectedCompanyUrl, URLs } from "@gc/urls";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    redirect: URLs.customer.identity(),
  },
  {
    path: URLs.customer.public.home(),
    component: () => import("@/views/public/index.vue"),
    children: [
      {
        path: URLs.customer.public.answerCombinedCommuterSurvey(true),
        component: () => import("@/views/public/commuter-survey/commuter-survey-tab-1.vue"),
      },
      {
        path: URLs.customer.public.answerCommuterSurvey(true),
        component: () => import("@/views/public/commuter-survey/commuter-survey-tab-2.vue"),
      },
      {
        path: URLs.customer.public.survey(true),
        component: () => import("@/views/public/survey/index.vue"),
      },
      {
        path: URLs.customer.public.verifyScorecard(true),
        component: () => import("@/views/public/scorecard-verification/index.vue"),
      },
    ],
  },
  {
    path: `/${SelectedCompanyUrl.PREFIX}/:selectedCompanyId/customer`,
    component: () => import("@/views/index.vue"),
    children: customerRoutes,
    beforeEnter: guards.companySelectionAndAuthenticationGuard,
  },
  {
    path: URLs.customer.identity(),
    component: () => import("@/views/identity.vue"),
  },
  {
    path: URLs.customer.logout(),
    component: () => import("@/views/logout.vue"),
  },
  {
    path: "*",
    component: () => import("@/views/index.vue"),
    children: [
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
