/* eslint-disable */

export const messages = {
  customer_esg_ratingRequest_title: "ESG-Rating-Anfrage",
  customer_esg_ratingRequest_back_to_survey_page: "Zurück zur Umfrage-Seite",
  customer_esg_ratingRequest_overview: "Übersicht",
  customer_esg_ratingRequest_survey: "Umfrage",
  customer_esg_ratingRequest_result: "Ergebnis",
  customer_esg_ratingRequest_progress: "Fortschritt",
  customer_esg_ratingRequest_open_survey: "Umfrage öffnen",
  customer_esg_ratingRequest_rating_information_title: "Bewertungsinformationen",
  customer_esg_ratingRequest_rating_information_1:
    "Das ESG-Rating hat sich weltweit als Qualitätsindex für nachhaltige Lieferketten und Unternehmensbewertungen etabliert. {companyName} bittet Ihr Unternehmen um eine Bewertung durch GLOBAL CLIMATE. Sie müssen die Umfrage im nächsten Tab beantworten, welche Fragen zu Umwelt-, Sozial- und Governance-Aspekten enthält. Da die Beantwortung einiger Fragen möglicherweise Recherchen in Ihrem Unternehmen erfordert, wird der Fortschritt nach Beantwortung jeder Frage gespeichert und Sie können die Fragebögen zu einem späteren Zeitpunkt weiter ausfüllen.<br/><br/>Die Antworten werden automatisch nach Übermittlung der Umfrage bewertet und das Score-Ergebnis wird mit <b>{companyName}</b> geteilt.",
  customer_esg_ratingRequest_rating_information_2:
    "Ihr Unternehmen kann durch Kauf einer Lizenz eine detaillierte Analyse Ihres Scores, Ihrer KPIs und einer Scorecard erhalten. Unser Expertenteam wird dann Ihre Ergebnisse weiter analysieren, z.B. die für einige Fragen bereitgestellten Dokumente und Zertifikate validieren. Nach dem Überprüfungsprozess werden Sie von GLOBAL CLIMATE informiert.",
  customer_esg_ratingRequest_sender_information_title: "Absenderinformationen",
  customer_esg_ratingRequest_sender: "Absender",
  customer_esg_ratingRequest_invitation_message: "Einladungsnachricht",
  customer_esg_ratingRequest_survey_questionnaires: "Fragebogen",
  customer_esg_ratingRequest_submit_info:
    "Sie müssen alle erforderlichen Fragen beantworten (ohne Validierungsfehler), bevor Sie die Umfrage absenden können.",
  customer_esg_ratingRequest_submit_valid:
    "Alle Fragen sind beantwortet und gültig - Sie müssen die Umfrage aktiv übermitteln, um die Ergebnisse zu erhalten.",
  customer_esg_ratingRequest_submit_warning:
    "Sie können die Antworten nicht mehr ändern, da die Umfrage bereits abgesendet wurde.",
  customer_esg_ratingRequest_result_in_review_title: "Ergebnis wird noch analysiert",
  customer_esg_ratingRequest_result_in_review_subtitle:
    "Unser Expertenteam analysiert Ihre Antworten. Sie erhalten eine E-Mail-Benachrichtigung, sobald die Ergebnisse verfügbar sind.",
  customer_esg_ratingRequest_result_answer_survey: "Umfrage beantworten",
  customer_esg_ratingRequest_upgrade_license: "Lizenz-Upgrade",
  customer_esg_ratingRequest_banner_text:
    "Profitieren Sie von einem detaillierten Scoring,<br />einer öffentlich teilbaren Scorecard, KPIs und mehr",
  customer_esg_ratingRequest_license_package_1: "Lizenz-Paket",
  customer_esg_ratingRequest_license_package_2: "Upgrade Ihrer Lizenz",
  customer_esg_ratingRequest_license_package_description:
    "Ohne eine Lizenz wird Ihr Gesamtergebnis mit <strong>{0}</strong> geteilt, jedoch können Sie das erzielte Ergebnis nicht selbst verwenden. Um ein detailliertes Scoring, eine öffentlich teilbare Scorecard und KPIs zu Ihren ESG-Rating-Ergebnissen zu erhalten, müssen Sie eine Lizenz erwerben. Unser Expertenteam wird Ihre Antworten im Detail analysieren, z.B. durch die Validierung aller von Ihnen zur Verfügung gestellten Dokumente und Zertifikate.",
  customer_esg_ratingRequest_rating_license: "ESG-Rating-Lizenz",
  customer_esg_ratingRequest_plus_vat: "zuzüglich Mehrwertsteuer",
  customer_esg_ratingRequest_small_companies: "Kleine Unternehmen",
  customer_esg_ratingRequest_small_companies_1: "Detaillierte Analyse der Antworten zu einem ESG-Rating",
  customer_esg_ratingRequest_small_companies_2: "Teilbare Scorecard",
  customer_esg_ratingRequest_small_companies_3: "KPIs",
  customer_esg_ratingRequest_medium_companies: "Mittlere Unternehmen",
  customer_esg_ratingRequest_medium_companies_1: "Detaillierte Analyse der Antworten zu einem ESG-Rating",
  customer_esg_ratingRequest_medium_companies_2: "Teilbare Scorecard",
  customer_esg_ratingRequest_medium_companies_3: "KPIs",
  customer_esg_ratingRequest_large_companies: "Große Unternehmen",
  customer_esg_ratingRequest_large_companies_1: "Detaillierte Analyse der Antworten zu einem ESG-Rating",
  customer_esg_ratingRequest_large_companies_2: "Teilbare Scorecard",
  customer_esg_ratingRequest_large_companies_3: "KPIs",
  customer_esg_ratingRequest_small_license: "GLOBAL COMPLIANCE Kleine Lizenz",
  customer_esg_ratingRequest_medium_license: "GLOBAL COMPLIANCE Mittlere Lizenz",
  customer_esg_ratingRequest_large_license: "GLOBAL COMPLIANCE Große Lizenz",
  customer_esg_ratingRequest_license_description_1: "Bitte überprüfen Sie die Rechnungsdaten und passen Sie sie an:",
  customer_esg_ratingRequest_license_description_2:
    "Wenn Sie fortfahren, wird eine Rechnung an die angegebene E-Mail-Adresse gesendet.<br/><br/>Sie können entweder über unseren Zahlungsdienstleister Stripe (Kreditkarte) oder per Überweisung bezahlen. Wenn Sie den Betrag direkt überweisen, wird die Lizenz freigeschaltet, sobald Ihre Zahlung verbucht ist.",
  customer_esg_ratingRequest_finish_license_title_1:
    "Vielen Dank, dass Sie eine GLOBAL COMPLIANCE Lizenz bestellt haben!",
  customer_esg_ratingRequest_finish_license_title_2: "Schließen Sie Ihre Zahlung ab, um die Lizenz freizuschalten",
  customer_esg_ratingRequest_finish_license_description_1_1:
    "Die Rechnung wurde per E-Mail an Ihre E-Mail-Adresse ({0}) gesendet. Sie können die Rechnung auch hier heruntergeladen.",
  customer_esg_ratingRequest_finish_license_description_1_2:
    "Wir übermitteln Ihnen die Rechnung an die angegebene E-Mail-Adresse ({0}), Sie können diese jedoch auch hier herunterladen.",
  customer_esg_ratingRequest_finish_license_description_2:
    "Ihre Lizenz wird sofort freigeschaltet, wenn die Zahlung über unseren Zahlungsdienstleister Stripe erfolgt.",
  customer_esg_ratingRequest_finish_license_description_3:
    "Wenn Sie den Betrag direkt auf das in der Rechnung angegebene Bankkonto überweisen, wird die Lizenz freigeschaltet, sobald Ihre Zahlung eingegangen ist.",
  customer_esg_ratingRequest_invoice: "Rechnung",
  customer_esg_ratingRequest_finish_payment_banner: "Schließen Sie Ihre Zahlung ab, um die Lizenz freizuschalten",
  customer_esg_ratingRequest_finish_payment: "Zahlung beenden",
  customer_esg_ratingRequest_start_survey: "Umfrage starten",

  customer_esg_selfAssessment_title: "ESG-Selbstbewertung",
  customer_esg_selfAssessment_overview_title: "Übersicht",
  customer_esg_selfAssessment_overview_info1:
    "Das ESG-Rating hat sich weltweit als der Qualitätsindex für nachhaltige Lieferketten und Unternehmensbewertungen etabliert.",
  customer_esg_selfAssessment_overview_info2:
    "Um von GLOBAL CLIMATE bewertet zu werden, muss Ihr Unternehmen die Umfrage im nächsten Tab beantworten. Diese beinhaltet Fragen zu Umwelt, Soziales und Governance. Da die Beantwortung einiger Fragen möglicherweise Recherchen in Ihrem Unternehmen erfordert, wird der Fortschritt nach der Beantwortung jeder Frage gespeichert und Sie können den Fragebogen zu einem späteren Zeitpunkt weiter ausfüllen.",
  customer_esg_selfAssessment_overview_info3:
    "Nach der Übermittlung der Antworten der Umfrage werden diese von GLOBAL CLIMATE überprüft. GLOBAL CLIMATE wird auch die Dokumente und Bescheinigungen validieren, die für einige Fragen hochgeladen werden müssen. Nach dem Überprüfungsprozess werden Sie von GLOBAL CLIMATE über Ihre endgültige Bewertung informiert.",
  customer_esg_selfAssessment_overview_information: "Informationen",
  customer_esg_selfAssessment_overview_process: "Prozess",
  customer_esg_selfAssessment_overview_step1: "Umfrage initiiert",
  customer_esg_selfAssessment_overview_step2: "Umfrage in Arbeit",
  customer_esg_selfAssessment_overview_step3: "Antwort-Auswertung im Gange",
  customer_esg_selfAssessment_overview_step4: "Ergebnis erhalten",
  customer_esg_selfAssessment_survey_title: "Umfrage",
  customer_esg_selfAssessment_survey_alert1:
    "Es müssen alle erforderlichen Fragen beantwortet sein (keine Validierungsfehler), bevor die Umfrage übermittelt werden kann.",
  customer_esg_selfAssessment_survey_alert2:
    "Alle Fragen wurden valide beantwortet - zur Ermittlung des Ratings muss die Umfrage aktiv übermittelt werden.",
  customer_esg_selfAssessment_result_title: "Ergebnis",
  customer_esg_selfAssessment_result_title1: "Ergebnis nicht verfügbar",
  customer_esg_selfAssessment_result_subtitle1:
    "Das Ergebnis ist nicht verfügbar, da die Umfrage nicht abgeschlossen ist. Unser Expertenteam wird Ihre Ergebnisse analysieren, nachdem Sie die Umfrage übermittelt haben.",
  customer_esg_selfAssessment_result_title2: "Laufende Expertenanalyse",
  customer_esg_selfAssessment_result_subtitle2:
    "Unser Expertenteam wertet die Antworten aus. Sobald die Ergebnisse vorliegen, erhalten Sie eine E-Mail-Benachrichtigung.",
  customer_esg_selfAssessment_result_subtitle3: "Rating Resultat",
  customer_esg_selfAssessment_result_scorecard: "Scorecard",
  customer_esg_selfAssessment_submitDialog_title: "Umfrage übermitteln",
  customer_esg_selfAssessment_submitDialog_desc1:
    "Nachdem Sie die Umfrage übermittelt haben, können Sie die Antworten nicht mehr ändern. Möchten Sie die Umfrage übermitteln?",
  customer_esg_selfAssessment_submitDialog_desc2:
    "Ihre Antworten wurden übermittelt. Sie erhalten eine E-Mail, sobald die Ergebnisse verfügbar sind.",

  customer_surveys_deadline: "Frist",
  customer_surveys_goToSurvey: "Zur Umfrage",
  customer_surveys_initiator: "Initiator",
  customer_surveys_noDeadline: "Keine Frist",
  customer_surveys_selfInitiated: "Selbst initiiert",
  customer_surveys_subtitle:
    "Die GLOBAL SUITE ermöglicht es Organisationen, Umfragen zur Selbsteinschätzung und Umfragen, die von ihren Partnerorganisationen angefordert werden, zu beantworten.<br>Wenn Partner Ihre Organisation dazu auffordern eine Umfrage zu beantworten, geschieht dies entweder im Rahmen der Erfüllung ihrer gesetzlichen LkSG-Anforderungen oder bei der Durchführung einer ESG-Risikoanalyse.<br>Bitte beantworten Sie Umfragen vor Ablauf der Frist. Sie können die Beantwortung der Umfragen jederzeit pausieren, der Fortschritt wird gespeichert.",
  customer_surveys_title: "Survey-Plattform",

  // LkSG Rating Request
  customer_lksgRatingRequest_title: "LkSG Rating Anfrage",
  customer_lksgRatingRequest_back_to_survey_page: "Zurück zur Umfrage-Seite",
  customer_lksgRatingRequest_overview: "Übersicht",
  customer_lksgRatingRequest_survey: "Umfrage",
  customer_lksgRatingRequest_progress: "Fortschritt",
  customer_lksgRatingRequest_open_survey: "Umfrage öffnen",
  customer_lksgRatingRequest_rating_information_title: "Information",
  customer_lksgRatingRequest_rating_information_1:
    "{companyName} bittet Ihr Unternehmen an einer Umfrage teilzunehmen. Sie können die Umfrage im nächsten Tab beantworten. Da die Beantwortung einiger Fragen möglicherweise Recherchen in Ihrem Unternehmen erfordert, wird der Fortschritt nach Beantwortung jeder Frage gespeichert und Sie können die Fragebögen zu einem späteren Zeitpunkt weiter ausfüllen.<br/><br/>Die Antworten werden automatisch nach Übermittlung der Umfrage mit <b>{companyName}</b> geteilt.",
  customer_lksgRatingRequest_sender_information_title: "Absenderinformationen",
  customer_lksgRatingRequest_sender: "Absender",
  customer_lksgRatingRequest_invitation_message: "Einladungsnachricht",
  customer_lksgRatingRequest_survey_questionnaires: "Fragebogen",
  customer_lksgRatingRequest_submit_info:
    "Sie müssen alle erforderlichen Fragen beantworten (ohne Validierungsfehler), bevor Sie die Umfrage absenden können.",
  customer_lksgRatingRequest_submit_valid:
    "Alle Fragen sind beantwortet und gültig - Sie müssen die Umfrage aktiv übermitteln, um die Ergebnisse zu erhalten.",
  customer_lksgRatingRequest_submit_warning:
    "Sie können die Antworten nicht mehr ändern, da die Umfrage bereits abgesendet wurde.",
  customer_lksgRatingRequest_result_answer_survey: "Umfrage beantworten",
  customer_lksgRatingRequest_start_survey: "Umfrage starten",
  customer_lksgRatingRequest_survey_status_1: "Umfrage initiiert",
  customer_lksgRatingRequest_survey_status_2: "Umfrage in Arbeit",
  customer_lksgRatingRequest_survey_status_3: "Umfrage übermittelt",
  customer_lksgRatingRequest_survey_start: "LkSG Umfrage beginnen",
  customer_lksgRatingRequest_survey_text_1:
    "Bei der Beantwortung der Fragen wird der Fortschritt der Umfrage automatisch gespeichert. Bitte beantworten Sie alle notwendigen Fragen.",
  customer_lksgRatingRequest_survey_text_2:
    "Ihre Antworten werden am Ende der Umfrage von GLOBAL CLIMATE ausgewertet und automatisch an Ihren Partner weitergeleitet.",
  customer_lksgRatingRequest_survey_text_3: "Bei Fragen jeglicher Art kontaktieren Sie bitte unser Support System.",
  customer_lksgRatingRequest_survey_text_4:
    "Wollen Sie die Umfrage starten? Ihr Partner <u>{partnerName}</u> wird automatisch darüber informiert, dass Sie an der Umfrage teilnehmen.",
  customer_lksgRatingRequest_survey_submit_survey: "Umfrage übermitteln",
  customer_lksgRatingRequest_survey_submitted_message: "Ihre Antworten wurden übermittelt.",
  customer_lksgRatingRequest_survey_submit_description:
    "Nachdem Sie die Umfrage abgeschickt haben, können Sie die Antworten nicht mehr ändern. Möchten Sie die Umfrage abschicken?",
  customer_lksgRatingRequest_survey_complete_survey_description_2:
    "Um die Umfrage abzuschließen, müssen alle Fragen ohne Validierungsfehler beantwortet werden.",
  customer_lksgRatingRequest_survey_number_of_questions: "Anzahl der Fragen",
  customer_lksgRatingRequest_survey_number_of_invalid_answered_questions: "Anzahl der Fragen ohne Validierungsfehler",
  customer_lksgRatingRequest_survey_and_more: "Und {0} weitere...",
  customer_lksgRatingRequest_survey_uploaded_documents: "Hochgeladene Dokumente",
  customer_lksgRatingRequest_survey_question: "Frage {0}",
};
