/* eslint-disable */
export const messages = {
  system_admin_tag_definitions: "Tag Definitions",
  system_admin_tag_definition: "Tag Definition",
  system_admin_tag_definition_all_except: "All except",
  system_admin_tag_definition_only_for: "Only for",
  system_admin_tag_definition_deleted: "Tag Definition successfully deleted",
  system_admin_tag_definition_deletion_confirmation: "Are you sure want to delete this tag definition permanently?",
  system_admin_tag_definition_info: "Tag Definition Info",
  system_admin_tag_definition_name: "Tag Definition Name",
  system_admin_tag_definition_entities: "Entities",
  system_admin_tag_definition_for_entities: "For Entities",
  system_admin_tag_definition_for_companies: "For Companies",
  system_admin_tag_definition_validation_regex: "Validation Regex",
  system_admin_tag_definition_created: "Tag Definition successfully created.",
  system_admin_tag_definition_updated: "Tag Definition successfully updated.",
  system_admin_tag_definition_selection: "Selection",
  system_admin_tag_definition_managed_description: "When set to \"On\" only support users or system admins will have the ability to create tags. This setting restricts tag creation to authorized personnel, ensuring a controlled and managed tagging process.",
  system_admin_tag_definition_entities_definition: "This field allows you to select the applicable entity type for the tag.",
  system_admin_tag_definition_companies_definition: "This field allows you to specify the companies for which the tag is applicable, providing flexibility in defining the scope of the tag based on the selected companies."
};
