export class AdminURLs {
  identity(redirectUrl?: string) {
    let path = `/identity`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  logout(redirectUrl?: string) {
    let path = `/logout`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  home() {
    return "";
  }
  account() {
    return `${this.home()}/profile`;
  }
  support() {
    return `${this.home()}/support`;
  }
  supportTicketDetails(id: string) {
    return `${this.home()}/support/${id}`;
  }
  companies() {
    return `${this.home()}/companies`;
  }
  companyDetails(id: string, tab?: number) {
    const query = tab ? `?tab=${tab}` : "";
    return `${this.home()}/companies/${id}${query}`;
  }
  companyGroups() {
    return `${this.home()}/company-groups`;
  }
  companyGroupDetails(id: string) {
    return `${this.home()}/company-groups/${id}`;
  }
  users() {
    return `${this.home()}/users`;
  }
  co2Database() {
    return `${this.home()}/co2-database`;
  }
  co2Prices() {
    return `${this.home()}/co2-type-prices`;
  }
  surveys() {
    return `${this.home()}/surveys`;
  }
  surveyDetails(id: string) {
    return `${this.surveys()}/${id}`;
  }
  surveyTemplates() {
    return `${this.home()}/survey-templates`;
  }
  esgRiskAnalysis() {
    return `${this.home()}/esg-risk-analyses`;
  }
  esgRiskAnalysisDetails(esgRiskAnalysisId: string) {
    return `${this.home()}/esg-risk-analyses/${esgRiskAnalysisId}`;
  }
  esgSelfAssessments() {
    return `${this.home()}/esg-self-assessments`;
  }
  esgSelfAssessmentDetails(surveyId: string) {
    return `${this.home()}/esg-self-assessments/${surveyId}`;
  }
  units() {
    return `${this.home()}/units`;
  }
  integration() {
    return `${this.home()}/integration`;
  }
  distanceCalc() {
    return `${this.home()}/distance-calc`;
  }
}
