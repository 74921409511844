export function copyId(id: string) {
  navigator.clipboard.writeText(id);
  // @ts-ignore
  this.successToast(this.$t("common_general_id_copied"));
}

declare module "vue/types/vue" {
  interface Vue {
    $copyId: (id: string) => void;
  }
}

export default {
  copyId,
  install(Vue: any) {
    Vue.prototype.$copyId = copyId;
  },
};
