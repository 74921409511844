import { SelectedCompanyMethod } from "../..";

export class CustomerSurveysURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}
  home() {
    return `${this.customerHome()}/surveys`;
  }
  esgSelfAssessment(id: string) {
    return `${this.home()}/esg-self-assessment/${id}`;
  }
  esgRatingRequest(id: string) {
    return `${this.home()}/esg-rating-request/${id}`;
  }
  lksgRatingRequest(id: string) {
    return `${this.home()}/lksg-rating-request/${id}`;
  }
}
