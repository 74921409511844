export enum RiskAnalysisState {
  CREATED = "CREATED",
  APPROVED_BY_SYSTEM_ADMIN = "APPROVED_BY_SYSTEM_ADMIN",
  APPROVED_BY_CUSTOMER = "APPROVED_BY_CUSTOMER",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface EsgRiskAnalysis {
  id: string;
  createdAt: number;
  deadlineAt: number;
  evaluationPeriod: number;
  invitationMessage: string;
  name: string;
  progress: number;
  state: RiskAnalysisState;
  surveyTemplate: EsgSurveyTemplate;
  shortSurveyTemplate: EsgSurveyTemplate;
  companyInfo: {
    id: string;
    name: string;
  };
  createdBy: {
    firstName: string;
    lastName: string;
  };
  partnerCount: number;
}

export interface EsgSurveyTemplate {
  id: string;
  name: string;
}
