/* eslint-disable */
export const messages = {
  system_admin_esg_self_assessment: "ESG Self Assessment",
  system_admin_esg_self_assessments: "ESG Self Assessments",
  system_admin_esg_self_assessment_details: "ESG Self Assessment Details",
  system_admin_esg_self_assessment_body: "Use this section to initiate an ESG Self Assessment. Please be aware that for every company only one ESG self assessment can be initiated per evaluation period. After the initiation of an ESG Self Assessment customers will be able to see a survey on their survey platform.",
  system_admin_esg_self_assessment_survey_template: "Survey Template",
  system_admin_esg_self_assessment_survey_template_name: "Survey template name",
  system_admin_create_esg_self_assessment: "Create ESG Self Assessment",
  system_admin_create_esg_self_assessment_success: "ESG Self Assessment created",
  system_admin_esg_self_assessment_validation_one_plus_year: "Evaluation period should be not higher than {0}",
};
