import { RouteConfig } from "vue-router";
import { administrationRoutes } from "./administration-routes";
import { partnerRoutes } from "./partner-routes";
import { sco2pesRoutes } from "./sco2pes-routes";
import { esgRoutes } from "./esg-routes";
import { supportRoutes } from "./support-routes";
import { surveysRoutes } from "@/router/surveys-routes";

export const customerRoutes: Array<RouteConfig> = [
  {
    path: "",
    component: () => import("@/views/welcome/index.vue"),
  },
  {
    path: `sco2pes`,
    component: () => import("@/views/sco2pes/index.vue"),
    children: sco2pesRoutes,
  },
  {
    path: `sco2res`,
    component: () => import("@/views/esg/index.vue"),
    children: esgRoutes,
  },
  {
    component: () => import("@/views/surveys/route.vue"),
    path: `surveys`,
    children: surveysRoutes,
  },
  {
    path: `administration`,
    component: () => import("@/views/administration/index.vue"),
    children: administrationRoutes,
  },
  {
    path: `partners`,
    component: () => import("@/views/partners/index.vue"),
    children: partnerRoutes,
  },
  {
    path: `support`,
    component: () => import("@/views/support/index.vue"),
    children: supportRoutes,
  },
  {
    path: `profile`,
    component: () => import("@/views/profile/index.vue"),
  },
  {
    path: `account-selection`,
    component: () => import("@/views/profile/account-selection.vue"),
  },
];
