import { countries } from "@gc/models";

const countriesObj: any = {};
Object.keys(countries).forEach((locale) => {
  countriesObj[locale] = {};
  for (let i = 0; i < countries[locale].length; i++) {
    const x = countries[locale][i];
    countriesObj[locale][x.code] = x.name;
  }
});

export default {
  countries,
  install(Vue: any) {
    Vue.prototype.$countries = countries;
    Vue.prototype.$countriesObj = countriesObj;
  },
};
