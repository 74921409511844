/* eslint-disable */
export const messages = {
  system_admin_user_add_company: "Unternehmen hinzufügen",
  system_admin_user_companies: "Unternehmen",
  system_admin_user_companies_info:
    "Es muss mindestens ein Unternehmen ausgewählt sein.<br />Im Bearbeitungsmodus ist das Löschen von Unternehmen noch nicht möglich.",
  system_admin_user_company_and_roles: "Unternehmen und Rollen",
  system_admin_user_company_name: "Name des Unternehmens",
  system_admin_user_company_user: "Unternehmen Nutzer",
  system_admin_user_auditor: "Wirtschaftsprüfer",
  system_admin_user_company_users: "Unternehmen Nutzer",
  system_admin_user_create_company_user: "Unternehmens-Nutzer anlegen",
  system_admin_user_create_system_user: "System-Admin anlegen",
  system_admin_user_created: "Erstellt",
  system_admin_user_deactivated: "Deaktiviert",
  system_admin_user_completed: "Abgeschlossen",
  system_admin_user_expired: "Abgelaufen",
  system_admin_user_edit_company_user: "Unternehmens-Nutzer bearbeiten",
  system_admin_user_edit_system_user: "System-Admin bearbeiten",
  system_admin_user_english: "Englisch",
  system_admin_user_german: "Deutsch",
  system_admin_user_profile_state: "Profil Status",
  system_admin_user_roles: "Rollensatz",
  system_admin_user_company_admin: "Administrator",
  system_admin_user_basic_access: "Basic",
  system_admin_user_ccf_user: "GLOBAL FOOTPRINT Nutzer",
  system_admin_user_esg_user: "GLOBAL COMPLIANCE Nutzer",
  system_admin_user_system_admin: "System Administrator",
  system_admin_user_system_admin_user: "System Administrator Nutzer",
  system_admin_user_system_roles: "System-Rollen",
  system_admin_user_system_user: "System Nutzer",
  system_admin_user_user_data: "Nutzerdaten",
  system_admin_user_registration_link_description:
    "Der Benutzer kann das Benutzerprofil unter folgenden Links vervollständigen:",
  system_admin_user_registration_link_valid_until: "Gültig bis",
  system_admin_user_registration_links: "Registrierungslinks",
  system_admin_user_update_user_warning:
    "Nutzer müssen sich neu anmelden, bevor Änderungen an den Rollen visuell wirksam werden",
  system_admin_user_deactivate_warning: "Nutzer können sich nicht mehr anmelden, wenn Sie deaktiviert werden",
  system_admin_user_attention: "Achtung",
  system_admin_user_email_dialog_title: "E-Mail versenden",
  system_admin_user_email_dialog_alert:
    "Achtung: Eine E-Mail wird gesendet. Bitte diese Funktionalität mit Bedacht ausführen um zu viele E-Mails zu vermeiden.",
};
