import { SelectedCompanyMethod } from "../..";

export class CustomerESGURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}
  home() {
    return `${this.customerHome()}/sco2res`;
  }
  riskAnalyses() {
    return `${this.home()}/esg/risk-analyses`;
  }
  riskAnalysesDetails(id: string) {
    return `${this.home()}/esg/risk-analyses/${id}`;
  }
}
