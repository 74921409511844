import { RouteConfig } from "vue-router";

export const administrationRoutes: Array<RouteConfig> = [
  {
    path: `company-details`,
    component: () => import("@/views/administration/company-details/index.vue"),
  },
  {
    path: `users`,
    component: () => import("@/views/administration/users/index.vue"),
  },
  {
    path: `licenses`,
    component: () => import("@/views/administration/licenses/index.vue"),
  },
];
