/* eslint-disable */
export const messages = {
  auth_back_to_login: "Zurück zum Login",
  auth_login_success: "Anmeldung erfolgreich",
  auth_new_password: "Neues Passwort",
  auth_new_password_repeat: "Neues Passwort wiederholen",
  auth_reset_password: "Passwort zurücksetzen",
  auth_forgot_password: "Passwort vergessen",
  auth_forgot_password_page_description:
    "Bitte geben Sie die E-Mail Adresse an, die Sie bei der Anmeldung für das GLOBAL CLIMATE Konto verwendet haben.\n\n Wir senden Ihnen eine E-Mail, mit der Sie Ihr Passwort zurücksetzen können.",
  auth_login: "Login",
  auth_reset_password_email_sent:
    "Eine E-Mail zum Zurücksetzen des Passwortes wurde an Sie gesendet. Bitte folgen Sie den Schritten in der E-Mail.",
  auth_reset_your_password: "Passwort zurücksetzen",
  auth_reset_your_password_description:
    "Der Link zum Zurücksetzen des Passworts ist ungültig. Klicken Sie hier, um einen neuen Link zum Zurücksetzen des Passworts anzufordern.",
  auth_password_changed: "Das Passwort wurde erfolgreich geändert",
  auth_complete_registration: "Registrierung beenden",
  auth_complete_registration_description:
    "Der Registrierungslink ist abgelaufen. Bitte kontaktieren Sie den Administrator des Accounts oder den GLOBAL CLIMATE Support, um einen neuen Registrierungslink anzufordern.",
  auth_confirm_password: "Password bestätigen",
  auth_registration_completed: "Registrierung vervollständigt",
  auth_invitation_email_sent: "Einladung wurde per E-Mail an den Benutzer gesendet.",
  auth_company_registration: "Registrierung",
  auth_company_information: "Unternehmensinformationen",
  auth_company_name: "Unternehmensname",
  auth_agree_with: "Ich habe die {0} gelesen und verstanden",
  auth_terms_conditions: "AGBs",
  auth_data_conditions: "Bedingungen für die Datensicherheit",
  auth_agreement_warning: "Den Registrierungsbedingungen muss zugestimmt werden",
  auth_not_registered: "Noch nicht registriert?",
  auth_register: "Registrieren",
  auth_register_and_login: "Registrieren & Anmelden",
  auth_invitation_information: "Einladungsinformationen",
  auth_invitation_code: "Einladungscode",
  auth_invitation_information_description:
    "Zur Registrierung auf der GLOBAL SUITE müssen Sie den in Ihrer Einladungs-E-Mail angegebene Invitation-Code angeben.",
  auth_personal_data: "Persönliche Daten",
  auth_lost_my_device: "Mein Gerät verloren",
  auth_lost_device: "Gerät verloren? Verwenden Sie den Wiederherstellungscode",

  auth_sales_information_form_title:
    "Die Nutzung der GLOBAL SUITE ist für Sie als Lieferant kostenlos. Unternehmen unterliegen jedoch oft Berichtspflichten, bei denen wir Sie unterstützen können.",
  auth_sales_information_form_body:
    "Wussten Sie, dass Unternehmen aus Deutschland mit mehr als 250 Mitarbeitern verpflichtet sind, eine CO<sub>2</sub>-Emissionsbilanz zu erstellen? Wenn Sie an unseren Produkten interessiert sind, bitten wir Sie, die folgenden Fragen zu beantworten:",
  auth_sales_information_form_question_1: "Sind Sie an einer Treibhausgasbilanz interessiert?",
  auth_sales_information_form_question_2:
    "Sind Sie an einer Lösung interessiert, um die Berichtspflichten des LkSG zu erfüllen?",
  auth_sales_information_form_question_3:
    "Bitte geben Sie Ihre Telefonnummer an, wir werden Sie über unsere Produkte informieren",
  auth_sales_information_telephone_number: "Telefonnummer",
};
