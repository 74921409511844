export const countries: { [key: string]: { code: string; name: string }[] } = {
  en: [
    { code: "AD", name: "Andorra" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "AF", name: "Afghanistan" },
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AI", name: "Anguilla" },
    { code: "AL", name: "Albania" },
    { code: "AM", name: "Armenia" },
    { code: "AO", name: "Angola" },
    { code: "AQ", name: "Antarctica" },
    { code: "AR", name: "Argentina" },
    { code: "AS", name: "American Samoa" },
    { code: "AT", name: "Austria" },
    { code: "AU", name: "Australia" },
    { code: "AW", name: "Aruba" },
    { code: "AX", name: "Åland Islands" },
    { code: "AZ", name: "Azerbaijan" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BB", name: "Barbados" },
    { code: "BD", name: "Bangladesh" },
    { code: "BE", name: "Belgium" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BG", name: "Bulgaria" },
    { code: "BH", name: "Bahrain" },
    { code: "BI", name: "Burundi" },
    { code: "BJ", name: "Benin" },
    { code: "BL", name: "Saint Barthélemy" },
    { code: "BM", name: "Bermuda" },
    { code: "BN", name: "Brunei Darussalam" },
    { code: "BO", name: "Bolivia (Plurinational State of)" },
    { code: "BQ", name: "Bonaire" },
    { code: "BR", name: "Brazil" },
    { code: "BS", name: "Bahamas" },
    { code: "BT", name: "Bhutan" },
    { code: "BV", name: "Bouvet Island" },
    { code: "BW", name: "Botswana" },
    { code: "BY", name: "Belarus" },
    { code: "BZ", name: "Belize" },
    { code: "CA", name: "Canada" },
    { code: "CC", name: "Cocos (Keeling) Islands" },
    { code: "CD", name: "Democratic Republic of Congo" },
    { code: "CF", name: "Central African Republic" },
    { code: "CG", name: "Congo" },
    { code: "CH", name: "Switzerland" },
    { code: "CI", name: "Côte d'Ivoire" },
    { code: "CK", name: "Cook Islands" },
    { code: "CL", name: "Chile" },
    { code: "CM", name: "Cameroon" },
    { code: "CN", name: "China" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Cuba" },
    { code: "CV", name: "Cabo Verde" },
    { code: "CW", name: "Curaçao" },
    { code: "CX", name: "Christmas Island" },
    { code: "CY", name: "Cyprus" },
    { code: "CZ", name: "Czechia" },
    { code: "DE", name: "Germany" },
    { code: "DJ", name: "Djibouti" },
    { code: "DK", name: "Denmark" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominican Republic" },
    { code: "DZ", name: "Algeria" },
    { code: "EC", name: "Ecuador" },
    { code: "EE", name: "Estonia" },
    { code: "EG", name: "Egypt" },
    { code: "EH", name: "Western Sahara" },
    { code: "ER", name: "Eritrea" },
    { code: "ES", name: "Spain" },
    { code: "ET", name: "Ethiopia" },
    { code: "FI", name: "Finland" },
    { code: "FJ", name: "Fiji" },
    { code: "FK", name: "Falkland Islands" },
    { code: "FM", name: "Federated States of Micronesia" },
    { code: "FO", name: "Faroe Islands" },
    { code: "FR", name: "France" },
    { code: "GA", name: "Gabon" },
    { code: "GB", name: "United Kingdom of Great Britain and Northern Ireland" },
    { code: "GD", name: "Grenada" },
    { code: "GE", name: "Georgia" },
    { code: "GF", name: "French Guiana" },
    { code: "GG", name: "Guernsey" },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GL", name: "Greenland" },
    { code: "GM", name: "Gambia" },
    { code: "GN", name: "Guinea" },
    { code: "GP", name: "Guadeloupe" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "GR", name: "Greece" },
    { code: "GS", name: "South Georgia and the South Sandwich Islands" },
    { code: "GT", name: "Guatemala" },
    { code: "GU", name: "Guam" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HK", name: "Hong Kong" },
    { code: "HM", name: "Heard Island and McDonald Islands" },
    { code: "HN", name: "Honduras" },
    { code: "HR", name: "Croatia" },
    { code: "HT", name: "Haiti" },
    { code: "HU", name: "Hungary" },
    { code: "ID", name: "Indonesia" },
    { code: "IE", name: "Ireland" },
    { code: "IL", name: "Israel" },
    { code: "IM", name: "Isle of Man" },
    { code: "IN", name: "India" },
    { code: "IO", name: "British Indian Ocean Territory" },
    { code: "IQ", name: "Iraq" },
    { code: "IR", name: "Islamic Republic of Iran" },
    { code: "IT", name: "Italy" },
    { code: "JE", name: "Jersey" },
    { code: "JM", name: "Jamaica" },
    { code: "JO", name: "Jordan" },
    { code: "JP", name: "Japan" },
    { code: "KE", name: "Kenya" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "KH", name: "Cambodia" },
    { code: "KI", name: "Kiribati" },
    { code: "KM", name: "Comoros" },
    { code: "KN", name: "Saint Kitts and Nevis" },
    { code: "KP", name: "Korea (the Democratic People's Republic of)" },
    { code: "KR", name: "Korea (the Republic of)" },
    { code: "KW", name: "Kuwait" },
    { code: "KY", name: "Cayman Islands" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "LA", name: "Lao People's Democratic Republic" },
    { code: "LB", name: "Lebanon" },
    { code: "LC", name: "Saint Lucia" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LK", name: "Sri Lanka" },
    { code: "LR", name: "Liberia" },
    { code: "LS", name: "Lesotho" },
    { code: "LT", name: "Lithuania" },
    { code: "LU", name: "Luxembourg" },
    { code: "LV", name: "Latvia" },
    { code: "LY", name: "Libya" },
    { code: "MA", name: "Morocco" },
    { code: "MC", name: "Monaco" },
    { code: "MD", name: "Republic of Moldova" },
    { code: "ME", name: "Montenegro" },
    { code: "MF", name: "Saint Martin (French part)" },
    { code: "MG", name: "Madagascar" },
    { code: "MH", name: "Marshall Islands" },
    { code: "MK", name: "North Macedonia" },
    { code: "ML", name: "Mali" },
    { code: "MM", name: "Myanmar" },
    { code: "MN", name: "Mongolia" },
    { code: "MO", name: "Macao" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "MQ", name: "Martinique" },
    { code: "MR", name: "Mauritania" },
    { code: "MS", name: "Montserrat" },
    { code: "MT", name: "Malta" },
    { code: "MU", name: "Mauritius" },
    { code: "MV", name: "Maldives" },
    { code: "MW", name: "Malawi" },
    { code: "MX", name: "Mexico" },
    { code: "MY", name: "Malaysia" },
    { code: "MZ", name: "Mozambique" },
    { code: "NA", name: "Namibia" },
    { code: "NC", name: "New Caledonia" },
    { code: "NE", name: "Niger" },
    { code: "NF", name: "Norfolk Island" },
    { code: "NG", name: "Nigeria" },
    { code: "NI", name: "Nicaragua" },
    { code: "NL", name: "Netherlands" },
    { code: "NO", name: "Norway" },
    { code: "NP", name: "Nepal" },
    { code: "NR", name: "Nauru" },
    { code: "NU", name: "Niue" },
    { code: "NZ", name: "New Zealand" },
    { code: "OM", name: "Oman" },
    { code: "PA", name: "Panama" },
    { code: "PE", name: "Peru" },
    { code: "PF", name: "French Polynesia" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PH", name: "Philippines" },
    { code: "PK", name: "Pakistan" },
    { code: "PL", name: "Poland" },
    { code: "PM", name: "Saint Pierre and Miquelon" },
    { code: "PN", name: "Pitcairn" },
    { code: "PR", name: "Puerto Rico" },
    { code: "PS", name: "State of Palestine" },
    { code: "PT", name: "Portugal" },
    { code: "PW", name: "Palau" },
    { code: "PY", name: "Paraguay" },
    { code: "QA", name: "Qatar" },
    { code: "RE", name: "Réunion" },
    { code: "RO", name: "Romania" },
    { code: "RS", name: "Serbia" },
    { code: "RU", name: "Russian Federation" },
    { code: "RW", name: "Rwanda" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "SB", name: "Solomon Islands" },
    { code: "SC", name: "Seychelles" },
    { code: "SD", name: "Sudan" },
    { code: "SE", name: "Sweden" },
    { code: "SG", name: "Singapore" },
    { code: "SH", name: "Saint Helena" },
    { code: "SI", name: "Slovenia" },
    { code: "SJ", name: "Svalbard" },
    { code: "SK", name: "Slovakia" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SM", name: "San Marino" },
    { code: "SN", name: "Senegal" },
    { code: "SO", name: "Somalia" },
    { code: "SR", name: "Suriname" },
    { code: "SS", name: "South Sudan" },
    { code: "ST", name: "Sao Tome and Principe" },
    { code: "SV", name: "El Salvador" },
    { code: "SX", name: "Sint Maarten (Dutch part)" },
    { code: "SY", name: "Syrian Arab Republic" },
    { code: "SZ", name: "Eswatini" },
    { code: "TC", name: "Turks and Caicos Islands" },
    { code: "TD", name: "Chad" },
    { code: "TF", name: "French Southern Territories" },
    { code: "TG", name: "Togo" },
    { code: "TH", name: "Thailand" },
    { code: "TJ", name: "Tajikistan" },
    { code: "TK", name: "Tokelau" },
    { code: "TL", name: "Timor-Leste" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TN", name: "Tunisia" },
    { code: "TO", name: "Tonga" },
    { code: "TR", name: "Türkiye" },
    { code: "TT", name: "Trinidad and Tobago" },
    { code: "TV", name: "Tuvalu" },
    { code: "TW", name: "Taiwan (Province of China)" },
    { code: "TZ", name: "Tanzania, the United Republic of" },
    { code: "UA", name: "Ukraine" },
    { code: "UG", name: "Uganda" },
    { code: "UM", name: "United States Minor Outlying Islands" },
    { code: "US", name: "United States of America" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "VA", name: "Holy See" },
    { code: "VC", name: "Saint Vincent and the Grenadines" },
    { code: "VE", name: "Venezuela (Bolivarian Republic of)" },
    { code: "VG", name: "Virgin Islands (British)" },
    { code: "VI", name: "Virgin Islands (U.S.)" },
    { code: "VN", name: "Viet Nam" },
    { code: "VU", name: "Vanuatu" },
    { code: "WF", name: "Wallis and Futuna" },
    { code: "WS", name: "Samoa" },
    { code: "YE", name: "Yemen" },
    { code: "YT", name: "Mayotte" },
    { code: "ZA", name: "South Africa" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" },
    { code: "NS", name: "Not Specified" },
  ],
  de: [
    { code: "AD", name: "Andorra" },
    { code: "AE", name: "Vereinigte Arabische Emirate" },
    { code: "AF", name: "Afghanistan" },
    { code: "AG", name: "Antigua und Barbuda" },
    { code: "AI", name: "Anguilla" },
    { code: "AL", name: "Albanien" },
    { code: "AM", name: "Armenien" },
    { code: "AO", name: "Angola" },
    { code: "AQ", name: "Antarktis" },
    { code: "AR", name: "Argentinien" },
    { code: "AS", name: "Samoa-Amerikanisch (US-Verwaltung)" },
    { code: "AT", name: "Österreich" },
    { code: "AU", name: "Australien" },
    { code: "AW", name: "Aruba" },
    { code: "AX", name: "Aland" },
    { code: "AZ", name: "Aserbeidschan" },
    { code: "BA", name: "Bosnien und Herzegowina" },
    { code: "BB", name: "Barbados" },
    { code: "BD", name: "Bangladesch" },
    { code: "BE", name: "Belgien" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BG", name: "Bulgarien" },
    { code: "BH", name: "Bahrain" },
    { code: "BI", name: "Burundi" },
    { code: "BJ", name: "Benin" },
    { code: "BL", name: "Saint Barthélemy (Franz.Verwaltung)" },
    { code: "BM", name: "Bermuda" },
    { code: "BN", name: "Brunei Darussalam" },
    { code: "BO", name: "Bolivien" },
    { code: "BQ", name: "Bonaire, St. Eustatius und Saba" },
    { code: "BR", name: "Brasilien" },
    { code: "BS", name: "Bahamas" },
    { code: "BT", name: "Bhutan" },
    { code: "BV", name: "Bouvetinsel" },
    { code: "BW", name: "Botsuana" },
    { code: "BY", name: "Belarus (Weissrussland)" },
    { code: "BZ", name: "Belize" },
    { code: "CA", name: "Kanada" },
    { code: "CC", name: "Kokosinseln (Keeling)" },
    { code: "CD", name: "Kongo, Demokratische Republik" },
    { code: "CF", name: "Zentralafrikanische Republik" },
    { code: "CG", name: "Kongo" },
    { code: "CH", name: "Schweiz" },
    { code: "CI", name: "Cote D'Ivoire (Elfenbeinkueste)" },
    { code: "CK", name: "Cook-Inseln Neuseel.Verwaltung" },
    { code: "CL", name: "Chile" },
    { code: "CM", name: "Kamerun" },
    { code: "CN", name: "China" },
    { code: "CO", name: "Kolumbien" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Kuba" },
    { code: "CV", name: "Kap Verde" },
    { code: "CW", name: "Curaçao" },
    { code: "CX", name: "Weihnachtsinsel (Austr.Verwaltung)" },
    { code: "CY", name: "Zypern" },
    { code: "CZ", name: "Tschechien" },
    { code: "DE", name: "Deutschland" },
    { code: "DJ", name: "Dschibuti" },
    { code: "DK", name: "Dänemark" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominikanische Republik" },
    { code: "DZ", name: "Algerien" },
    { code: "EC", name: "Ecuador" },
    { code: "EE", name: "Estland" },
    { code: "EG", name: "Ägypten" },
    { code: "EH", name: "Westsahara Eigenst.Staat" },
    { code: "ER", name: "Eritrea" },
    { code: "ES", name: "Spanien" },
    { code: "ET", name: "Äthiopien" },
    { code: "FI", name: "Finnland" },
    { code: "FJ", name: "Fidschi" },
    { code: "FK", name: "Falklandinseln (Malvinen)" },
    { code: "FM", name: "Mikronesien" },
    { code: "FO", name: "Faeroeer Inseln Dän.Verwaltung" },
    { code: "FR", name: "Frankreich" },
    { code: "GA", name: "Gabun" },
    { code: "GB", name: "Großbritannien" },
    { code: "GD", name: "Grenada" },
    { code: "GE", name: "Georgien" },
    { code: "GF", name: "Französisch Guayana" },
    { code: "GG", name: "Guernsey Insel" },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GL", name: "Grönland (Dän.Verwaltung)" },
    { code: "GM", name: "Gambia" },
    { code: "GN", name: "Guinea" },
    { code: "GP", name: "Guadeloupe (Franz.Verwaltung)" },
    { code: "GQ", name: "Äquatorial Guinea" },
    { code: "GR", name: "Griechenland" },
    { code: "GS", name: "Südgeorgien und Süd-Sandwich-Insel" },
    { code: "GT", name: "Guatemala" },
    { code: "GU", name: "Guam (US-Verwaltung)" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HK", name: "Hongkong" },
    { code: "HM", name: "Heard und Mc Donald Inseln (unter Austr.Verw.)" },
    { code: "HN", name: "Honduras" },
    { code: "HR", name: "Kroatien" },
    { code: "HT", name: "Haiti" },
    { code: "HU", name: "Ungarn" },
    { code: "ID", name: "Indonesien" },
    { code: "IE", name: "Irland" },
    { code: "IL", name: "Israel" },
    { code: "IM", name: "Man, Insel" },
    { code: "IN", name: "Indien" },
    { code: "IO", name: "Brit.Terr.Im Indischen Ozean" },
    { code: "IQ", name: "Irak" },
    { code: "IR", name: "Iran" },
    { code: "IT", name: "Italien" },
    { code: "JE", name: "Jersey Insel" },
    { code: "JM", name: "Jamaika" },
    { code: "JO", name: "Jordanien" },
    { code: "JP", name: "Japan" },
    { code: "KE", name: "Kenia" },
    { code: "KG", name: "Kirgisistan" },
    { code: "KH", name: "Kambodscha" },
    { code: "KI", name: "Kiribati" },
    { code: "KM", name: "Komoren" },
    { code: "KN", name: "St.Kitts und Nevis" },
    { code: "KP", name: "Korea, Demokratische Volksrepublik" },
    { code: "KR", name: "Korea, Republik" },
    { code: "KW", name: "Kuwait" },
    { code: "KY", name: "Kaimaninseln" },
    { code: "KZ", name: "Kasachstan" },
    { code: "LA", name: "Laos" },
    { code: "LB", name: "Libanon" },
    { code: "LC", name: "St.Lucia" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LK", name: "Sri Lanka" },
    { code: "LR", name: "Liberien" },
    { code: "LS", name: "Lesotho" },
    { code: "LT", name: "Litauen" },
    { code: "LU", name: "Luxemburg" },
    { code: "LV", name: "Lettland" },
    { code: "LY", name: "Libysch-Arabische Dschamahirija" },
    { code: "MA", name: "Marokko" },
    { code: "MC", name: "Monaco" },
    { code: "MD", name: "Moldau, Republik" },
    { code: "ME", name: "Montenegro" },
    { code: "MF", name: "Saint Martin (Franz. Verwaltung)" },
    { code: "MG", name: "Madagaskar" },
    { code: "MH", name: "Marshall Inseln" },
    { code: "MK", name: "Republik Nordmazedonien" },
    { code: "ML", name: "Mali" },
    { code: "MM", name: "Myanmar" },
    { code: "MN", name: "Mongolei" },
    { code: "MO", name: "Macao" },
    { code: "MP", name: "Marianen, Nördliche" },
    { code: "MQ", name: "Martinique Franz.Verwaltung" },
    { code: "MR", name: "Mauretanien" },
    { code: "MS", name: "Montserrat (GB-Verwaltung)" },
    { code: "MT", name: "Malta" },
    { code: "MU", name: "Mauritius" },
    { code: "MV", name: "Malediven" },
    { code: "MW", name: "Malawi" },
    { code: "MX", name: "Mexiko" },
    { code: "MY", name: "Malaysia" },
    { code: "MZ", name: "Mosambik" },
    { code: "NA", name: "Namibia" },
    { code: "NC", name: "Neukaledonien" },
    { code: "NE", name: "Niger" },
    { code: "NF", name: "Norfolkinsel" },
    { code: "NG", name: "Nigeria" },
    { code: "NI", name: "Nicaragua" },
    { code: "NL", name: "Niederlande" },
    { code: "NO", name: "Norwegen" },
    { code: "NP", name: "Nepal" },
    { code: "NR", name: "Nauru" },
    { code: "NU", name: "Niue (Neuseel.Verwaltung)" },
    { code: "NZ", name: "Neuseeland" },
    { code: "OM", name: "Oman" },
    { code: "PA", name: "Panama" },
    { code: "PE", name: "Peru" },
    { code: "PF", name: "Französisch Polynesien" },
    { code: "PG", name: "Papua Neuguinea" },
    { code: "PH", name: "Philippinen" },
    { code: "PK", name: "Pakistan" },
    { code: "PL", name: "Polen" },
    { code: "PM", name: "St.Pierre und Miquelon" },
    { code: "PN", name: "Pitcairninsel" },
    { code: "PR", name: "Puerto Rico (US-Verwaltung)" },
    { code: "PS", name: "Palästina" },
    { code: "PT", name: "Portugal" },
    { code: "PW", name: "Palau" },
    { code: "PY", name: "Paraguay" },
    { code: "QA", name: "Katar" },
    { code: "RE", name: "Reunion" },
    { code: "RO", name: "Rumänien" },
    { code: "RS", name: "Serbien (exkl. Kosovo)" },
    { code: "RU", name: "Russische Föderation" },
    { code: "RW", name: "Ruanda" },
    { code: "SA", name: "Saudi-Arabien" },
    { code: "SB", name: "Salomonen" },
    { code: "SC", name: "Seychellen" },
    { code: "SD", name: "Sudan" },
    { code: "SE", name: "Schweden" },
    { code: "SG", name: "Singapur" },
    { code: "SH", name: "St.Helena" },
    { code: "SI", name: "Slowenien" },
    { code: "SJ", name: "Spitzb. und Jan Mayen Insel (Norw.Verw.)" },
    { code: "SK", name: "Slowakei" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SM", name: "San Marino" },
    { code: "SN", name: "Senegal" },
    { code: "SO", name: "Somalia" },
    { code: "SR", name: "Suriname" },
    { code: "SS", name: "Südsudan" },
    { code: "ST", name: "Sao Tome und Principe" },
    { code: "SV", name: "El Salvador" },
    { code: "SX", name: "St. Martin" },
    { code: "SY", name: "Syrien" },
    { code: "SZ", name: "Eswatini" },
    { code: "TC", name: "Turks- und Caicosinseln" },
    { code: "TD", name: "Tschad" },
    { code: "TF", name: "Franz. Südgebiete" },
    { code: "TG", name: "Togo" },
    { code: "TH", name: "Thailand" },
    { code: "TJ", name: "Tadschikistan" },
    { code: "TK", name: "Tokelau (Neuseel.Verwaltung)" },
    { code: "TL", name: "Timor-Leste" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TN", name: "Tunesien" },
    { code: "TO", name: "Tonga" },
    { code: "TR", name: "Türkei" },
    { code: "TT", name: "Trinidad und Tobago" },
    { code: "TV", name: "Tuvalu" },
    { code: "TW", name: "Taiwan" },
    { code: "TZ", name: "Tansania, Vereinigte Republik" },
    { code: "UA", name: "Ukraine" },
    { code: "UG", name: "Uganda" },
    { code: "UM", name: "USA - Sonstige Kleine Inseln" },
    { code: "US", name: "USA - Vereinigte Staaten" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Usbekistan" },
    { code: "VA", name: "Vatikan" },
    { code: "VC", name: "Sankt Vincent und die Grenadinen" },
    { code: "VE", name: "Venezuela (Bolivarische Republik)" },
    { code: "VG", name: "Jungferninseln (britisch)" },
    { code: "VI", name: "Jungferninseln (US)" },
    { code: "VN", name: "Vietnam" },
    { code: "VU", name: "Vanuatu" },
    { code: "WF", name: "Wallis und Futuna" },
    { code: "WS", name: "Samoa" },
    { code: "YE", name: "Jemen" },
    { code: "YT", name: "Mayotte" },
    { code: "ZA", name: "Südafrika" },
    { code: "ZM", name: "Sambia" },
    { code: "ZW", name: "Zimbabwe" },
  ],
};
export const getCountryName = (code: string, locale: string): string => {
  // @ts-ignore
  const country = countries[locale].filter((item) => item.code === code);
  if (country.length > 0) {
    return country[0].name;
  }
  return code;
};
