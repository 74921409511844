/* eslint-disable */
export const messages = {
  system_admin_sco2pes_financial_scope: "Scope",
  system_admin_sco2pes_financial_co2_type: "CO<sub>2</sub> Type",
  system_admin_sco2pes_financial_co2_type_price: "CO<sub>2</sub> Type Price",
  system_admin_sco2pes_financial_co2_type_prices: "CO<sub>2</sub> Type Prices",
  system_admin_sco2pes_co2_type_price_do_you_really_want_to_delete_the_co2_type_price: 'Are you sure you want to delete the CO<sub>2</sub> Type price?',
  system_admin_sco2pes_co2_type_price_deleted: "CO<sub>2</sub> Type Price deleted",
  system_admin_sco2pes_co2_type_price_versions_add_version: "Add Version",
  system_admin_sco2pes_co2_type_price_version_validation_1: "<i>From</i> and <i>Until</i> must be set",
  system_admin_sco2pes_co2_type_price_version_validation_2: "<i>Until</i> must be after <i>From</i>",
  system_admin_sco2pes_co2_type_price_successfully_edited: "CO<sub>2</sub> Type Price successfully edited",
  system_admin_sco2pes_co2_type_price_successfully_created: "CO<sub>2</sub> Type Price successfully created",
  system_admin_sco2pes_co2_type_price_validations: "Validations",
  system_admin_sco2pes_co2_type_price_validation_1: "Both names are provided and a CO<sub>2</sub> Type is selected.",
  system_admin_sco2pes_co2_type_price_validation_3: "All prices are positive.",
  system_admin_sco2pes_co2_type_price_meta_data: "Meta Data",
  system_admin_sco2pes_co2_type_price_versions: "Versions",
  system_admin_sco2pes_co2_type_price_validity: "Validity",
  system_admin_sco2pes_co2_type_price_delete_version: "Delete version",
  system_admin_sco2pes_co2_type_price_price: "Price",

  system_admin_sco2pes_affected_title: "These companies will be affected",
  system_admin_sco2pes_affected_subtitle: "Before you save, please check the following companies that will be affected by this changes.",
  system_admin_sco2pes_affected_none_title: "No companies will be affected by this update",
  system_admin_sco2pes_affected_none_subtitle: "You can proceed by clicking on save button",
  system_admin_sco2pes_affected_company_name: "Company",
  system_admin_sco2pes_affected_financial_models: "Financial models",
  system_admin_sco2pes_affected_financial_bookings: "Financial bookings",

  system_admin_sco2pes_financial_category: "CO<sub>2</sub> Category",
  system_admin_sco2pes_financial_versions: "Versions",
  system_admin_sco2pes_financial_price_per_unit: "Price Per Unit",
  system_admin_sco2pes_financial_price_per_unit_selected: "Price Per {unit}",
  system_admin_sco2pes_financial_co2_type_deactivated: "Deactivated CO2 type",
  system_admin_sco2pes_financial_co2_type_active: "Active CO2 type",
  system_admin_sco2pes_financial_co2_type_open: "Open CO2 type dialog",
};
