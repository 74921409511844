import { SelectedCompanyMethodArgs } from "../..";
import { SelectedCompanyUrl } from "../..";
import { CustomerAdministrationURLs } from "./administration-urls";
import { CustomerPartnerURLs } from "./partner-urls";
import { CustomerSco2pesURLs } from "./sco2pes-urls";
import { CustomerESGURLs } from "./esg-urls";
import { CustomerSupportURLs } from "./support-urls";
import { CustomerSurveysURLs } from "./surveys-urls";
import { PublicURLs } from "./public-urls";

export class CustomerURLs {
  identity(redirectUrl?: string) {
    let path = `/identity`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  logout(redirectUrl?: string) {
    let path = `/logout`;

    if (redirectUrl) {
      path += `?redirect-url=${encodeURIComponent(redirectUrl)}`;
    }

    return path;
  }
  home(args?: SelectedCompanyMethodArgs) {
    const companyId = args?.selectedCompanyId || SelectedCompanyUrl.extractId(args?.log);
    return `/${SelectedCompanyUrl.PREFIX}/${companyId}/customer`;
  }
  account() {
    return `${this.home()}/profile`;
  }
  accountSelection() {
    return `${this.home()}/account-selection`;
  }
  readonly public = new PublicURLs();
  readonly esg = new CustomerESGURLs(this.home);
  readonly sco2pes = new CustomerSco2pesURLs(this.home);
  readonly partner = new CustomerPartnerURLs(this.home);
  readonly administration = new CustomerAdministrationURLs(this.home);
  readonly support = new CustomerSupportURLs(this.home);
  readonly surveys = new CustomerSurveysURLs(this.home);
}
