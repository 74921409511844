/* eslint-disable */
export const messages = {
  customer_faq_title: "FAQ und Glossar zur Nachhaltigkeit",
  customer_faq_subtitle: "Finden Sie die Antwort auf Ihre Fragen",
  customer_faq_cs_title: "Können Sie Ihre Antwort nicht finden?",
  customer_faq_cs_button: "Support kontaktieren",
  customer_faq_popular_topics: "Beliebte Themen",
  customer_faq_topic_not_available: "Themen nicht verfügbar",
  customer_faq_topic_not_available_description: "Wir können Ihr Thema nicht finden, bitte kontaktieren Sie unseren Support für Hilfe",
  customer_faq_topics: "Themen",
  customer_faq_keyword_search: "FAQ Stichwortsuche",
  customer_faq_q_1: "ESG",
  customer_faq_a_1: "ESG (Environment, Sovial und Governance) wird als erweiterter Begriff für CSR (Corporate Social Responsibility) verwendet und gilt als Kriterium für den Beitrag der Wirtschaft zu einer nachhaltigen Entwicklung. für viele Nachhaltigkeitsstandards bilden die ESG Kriterien die Basis",
  customer_faq_q_2: "CO<sub>2</sub>-Handabdruck",
  customer_faq_a_2: "Der bestehende Ansatz des Fußabdrucks ist auf negative ökologische Auswirkungen von Individuen, Organisationen oder Ländern fokussiert. Der Handabdruck soll demgegenüber den gesellschaftlichen Mehrwert bzw. positive Nachhaltigkeitswirkungen von Produkten erfassen, messen und bewerten sowie die soziale und ökonomische Dimension in die Betrachtung einbeziehen",
  customer_faq_q_3: "Scope 1, Scope 2, Scope 3",
  customer_faq_a_3: "Einteilung der Emissionen in direkte und indirekte Emissionen<br><br>Scope 1: Direkte Emissionen die durch die Verbrennung von fossilen Brennstoffen in der Produktion oder durch Prozesse im Unternehmen selbst entstehen.<br><br>Scope 2: Indirekte Emissionen die durch den Zukauf von Energie oder Strom verursacht werden.<br><br>Scope 3: Indirekte Emissionen die durch Prozesse entstehen, die außerhalb des Unternehmens stattfinden, aber im Zusammenhang mit der Produktion und dem Betrieb des Unternehmens stehen. Im Vergleich zu Scope 1 und 2 sind die SE Emissionen oft schwieriger zu messen und zu kontrollieren.",
  customer_faq_q_4: "Product Carbon Footprint",
  customer_faq_a_4: "PCF (Product Carbon Footprint) steht für die von einem Produkt verursachten Treibhausgasemissionen über seinen gesamten Lebenszyklus. Je nach Bilanzgrenze unterscheidet man dabei zwischen einem Cradle to Grave (von der Wiege bis zum Grab) bzw. einem Cradle to Gate (von der Wiege bis zum Werkstor) Ansatz. Der Product Carbon Footprint ist keine direkte Ableitung des Corporate Cabron Footprint (CCF geteilt durch produzierte Menge ist nicht gleich PCF",
  customer_faq_q_5: "Treibhausgasemissionen",
  customer_faq_a_5: "Als Treibhausgase werden diejenigen Gase in der Atmosphäre mit einem Einfluss auf die Energiebilanz der Erde bezeichnet. Sie bewirken also den sogenannten Treibhausgaseffekt. Die bekanntesten Treibhausgase Kohlenstoffdioxid (CO₂), Methan und Lachgas sind natürlicherweise in geringen Konzentrationen in der Atmosphäre zu finden. Durch verschiedene menschengemachte Quellen hat sich der Anteil seit Beginn des letzten Jahrhunderts jedoch deutlich erhöht",
  customer_faq_q_6: "Life Cycle Analysis",
  customer_faq_a_6: "LCA (Life Cycle Analysis) steht für die Lebenszyklusanalyse oder Ökobilanzierung eines bestimmten Produktes anhand seiner gesamten Umweltauswirkungen. Anders als beim Product Carbon Footprint werden hierbei die Umweltauswirkungen in sogenannte Impact Kategorien unterteilt: » Klimawandel » Ozonabbau » Versauerung » Eutrophierung » Ressourcenerschöpfung » Humantoxizität » Ökotoxizität » Bodennutzung » Wasserverbrauch » Ionisierende Strahlung » Feinstaubemissionen Die Berechnung einer LCA ist sehr zeitaufwendig. Eine LCA kann durch Verifizierung Dritter als EPD (Environmental Product Declaration) international verwendet werden",
  customer_faq_q_7: "CO<sub>2</sub>-Äquivalente",
  customer_faq_a_7: "CO<sub>2</sub>-eq sind eine Maßeinheit zur Vereinheitlichung der Klimawirkung unterschiedlicher Treibhausgase. Um die Wirkung verschiedener Treibhausgase vergleichbar zu machen wird das so genannte «Globale Erwärmungspotenzial» (Global Warming Potential) definiert. Dieser Index drückt die Erwärmungswirkung einer bestimmten Menge eines Treibhausgases über einen festgelegten Zeitraum (meist 100 Jahre) im Vergleich zu derjenigen von CO<sub>2</sub> aus. So hat z.B. Methan eine 28x größere Klimawirkung als CO<sub>2</sub>, bleibt aber weniger lange in der Atmosphäre. Die Klimawirkung von Lachgas übersteigt die von CO<sub>2</sub> sogar um beinahe das 300fache",
  customer_faq_q_8: "Treibhausgasbilanzierung",
  customer_faq_a_8: "Unter Treibhausgasbilanzierung versteht man die Zuordnung der entstandenen Treibhausgase nach bestimmten Einteilungskriterien. Durch Standardisierungen der Bilanzierung wie das Greenhouse Gas Protocol können Treibhausgasbilanzen zwischen Unternehmen verglichen werden",
  customer_faq_q_9: "Corporate Carbon Footprint",
  customer_faq_a_9: "CCF (Corporate Carbon Footprint) steht für die von einem Unternehmen in einem gewissen Zeitraum versursachten gesamten Treibhausgasemissionen ausgedrückt in Tonnen CO<sub>2</sub>-eq. Die Festlegung der Bilanzgrenzen bestimmt dabei, welche Emissionen in die Treibhausgasbilanzierung einfließen bzw. nicht einfließen. Die Einteilung erfolgt dabei in direkte (Scope 1) und indirekte (Scope 2 und Scope 3) Emissionen",
  customer_faq_q_10: "Science Based Targets Initiative",
  customer_faq_a_10: "Die Science Based Targets-Initiative wurde 2015 ins Leben gerufen, um Unternehmen dabei zu helfen, Emissionsreduktionsziele im Einklang mit der Klimawissenschaft und den Zielen des Pariser Abkommens festzulegen",
  customer_faq_q_11: "EU Green Deal",
  customer_faq_a_11: "Im Rahmen des europäischen Grünen Deals hat sich die EU mit dem Europäischen Klimagesetzt das verbindliche Ziel gesetzt, bis 2050 die Klimaneutralität zu erreichen. Der Grüne Deal unterstützt diesen Wandel hin zu einer gerechten und prosperierenden Gesellschaft mit einer modernen und wettbewerbsfähigen Wirtschaft. Den rechtlichen Rahmen für den Green Deal bildet dabei das „Fit for 55“ Paket",
  customer_faq_q_12: "Corporate Sustainability Reporting",
  customer_faq_a_12: "Die im November 2022 vom EU-Parlament verabschiedete Corporate Sustainability Reporting Directive (CSRD) ändert den Umfang und die Art der Nachhaltigkeitsberichterstattung von Unternehmen tiefgreifend. Mit der CSRD werden bestehende Regeln zur nichtfinanziellen Berichterstattung erheblich erweitert. Alle an einem EU-regulierten Markt notierten Unternehmen (mit Ausnahme von Kleinstunternehmen) sind von der neuen Berichtspflicht erfasst. Zudem sind alle nicht kapitalmarkt-orientierten Betriebe von der CSRD erfasst, wenn sie zwei der drei folgenden Kriterien erfüllen: » Bilanzsumme > 20 Mio. Euro » Nettoumsatzerlöse > 40 Mio. Euro » Zahl der Beschäftigten > 250 Schätzungsweise sind damit rund 50.000 Unternehmen in der EU betroffen",
  customer_faq_q_13: "EU ETS?",
  customer_faq_a_13: "Der Europäische Emissionshandel (EU-ETS) wurde 2005 zur Umsetzung des internationalen Klimaschutzabkommens von Kyoto eingeführt und ist das zentrale europäische Klimaschutzinstrument. Neben den 27 EU-Mitgliedstaaten haben sich auch Norwegen, Island und Liechtenstein dem EU Emissionshandel angeschlossen (EU 30). Das Vereinigte Königreich nahm bis zum 31.12.2020 am EU-ETS teil. Seit dem 01.01.2021 ist dort ein nationales Emissionshandelssystem in Kraft. Im EU-ETS werden die Emissionen von europaweit rund 10.000 Anlagen der Energiewirtschaft und der energieintensiven Industrie erfasst. Zusammen verursachen diese Anlagen rund 36% der Treibhausgasemissionen in Europa. Seit 2012 ist auch der innereuropäische Luftverkehr in den EU-ETS einbezogen",
  customer_faq_q_14: "Green Claim Directive",
  customer_faq_a_14: "Die Richtlinie zielt darauf ab, irreführende Umweltaussagen auf den EU-Märkten zu beseitigen und Bedenken wegen Greenwashing auszuräumen, indem sie die ersten detaillierten Regeln der EU dafür festlegt, wie Unternehmen ihre Umweltauswirkungen und -leistung vermarkten sollten. Die neuen Anforderungen werden erhebliche Änderungen an der Art und Weise erforderlich machen, wie viele Unternehmen derzeit ihre Umweltaussagen nachweisen und kommunizieren und wie sie Informationen über ihre Umweltfreundlichkeit verwalten. Sobald die Richtlinie in Kraft tritt, haben die Mitgliedstaaten 18 Monate Zeit, sie in nationales Recht umzusetzen",
  customer_faq_q_15: "EU-Taxonomie",
  customer_faq_a_15: "Um die Klima- und Energieziele der EU zu erreichen, müssen Investitionen in nachhaltige Projekte und Aktivitäten gelenkt werden. Eine klare Definition des Begriffs „nachhaltig“ ist daher erforderlich. Dafür wurde die EU-Taxonomie Verordnung geschaffen. Sie ist das gemeinsame Klassifizierungssystem für nachhaltige Wirtschaftstätigkeiten",
  customer_faq_q_16: "EU Fit for 55",
  customer_faq_a_16: "Das Fit for 55 Paket ist ein umfangreiches Rechtssetzungspaket, wodurch die Union in die Lage versetzt werden soll, bis zum Jahr 2030 das Klimaziel von mind. 55% Emissionsreduktion gegenüber 1990 sowie die Klimaneutralität bis 2050 zu erreichen. Die CSRD, CSDDD, CBAM und weitere sind Teil dieses Paketes",
  customer_faq_q_17: "EU Klimagesetz",
  customer_faq_a_17: "Das Klimagesetz legt als langfristiges Klimaziel der EU die Klimaneutralität bis 2050 fest. Als Zwischenziel sollen die Netto- Treibhausgasemissionen bis 2030 um 55 % gesenkt werden. Die EU und ihre Mitgliedstaaten müssen Maßnahmen ergreifen, um diese Klimaziele und den Beitrag der EU zum Übereinkommen von Paris zu verwirklichen",
  customer_faq_q_18: "CSDDD",
  customer_faq_a_18: "Die Corporate Sustainability Due Diligence Directive zielt darauf ab, negative Auswirkungen auf die Menschenrechte und die Umwelt in den Wertschöpfungsketten von Unternehmen zu verhindern bzw. zu mindern. Dadurch soll zum Übergang zu einer nachhaltigen Wirtschaft, insbesondere unter dem Leitbild des Green Deal beigetragen werden. Unternehmen sollen die durch ihre Tätigkeit verursachten negativen Auswirkungen ermitteln, verhindern und dafür Rechenschaft ablegen. Zur Erfüllung dieses Zwecks müssen angemessene Unternehmensführungs- und Managementsysteme etabliert werden. Für Opfer von negativen Auswirkungen auf die Menschenrechte und die Umwelt werden verbesserte Abhilfemaßnahmen geschaffen",
  customer_faq_q_19: "CBAM",
  customer_faq_a_19: "CBAM ist ein Klimaschutzinstrument der EU und gilt das Pendant zum EU Emissionshandelssystem (EU ETS). Die erste Berichtsplficht im Rahmen der CBAM VO startet mit Oktober 2023. Ab 2026 müssen beim Import bestimmter Waren bei deren Produktion in Drittländern Treibhausgase ausgestoßen wurden, CBAM Zertifikate erworben werden. Die Menge der zu erwerbenden Zertifikate richtet sich dabei nach den bei der Produktion entstandenen Emissionen. Der Preis pro Zertifikate richtet sich nach dem Preis der EU ETS Zertifikate (EUA Auktionspreis).",
  customer_faq_q_20: "Corporate Carbon Footprint (CO<sub>2</sub>-Bilanz)",
  customer_faq_a_20: "Der Corporate carbon Footprint misst die gesamten Treibhausgasemissionen, die direkt und indirekt von einer Person, einer Organisation, einem Ereignis oder einem Produkt verursacht werden.",
  customer_faq_q_21: "Greenhouse Gas protocol (GHG Protocol)",
  customer_faq_a_21: "Das GHG Protocol ist ein international anerkannter Standard für die Bilanzierung von Unternehmensemissionen.",
  customer_faq_q_22: "Grundsätze Corporate Carbon Footprint (CO<sub>2</sub>-Bilanz)",
  customer_faq_a_22: "Relevanz:<br>Alle wesentlichen Emissionsquellen müssen bei der Erstellung einer CO<sub>2</sub>-Bilanz (Corporate Carbon Footprint) für ein Unternehmen berücksichtigt werden. Der Bericht soll ein realistisches Bild innerhalb und außerhalb des Unternehmens darstellen.<br><br>Vollständigkeit:<br>Alle relevanten Emissionsquellen innerhalb der Systemgrenzen müssen berücksichtigt werden.<br><br>Transparenz:<br>Die Ergebnisse sollen transparent und eindeutig nachvollziehbar dargestellt werden.<br><br>Konsistenz:<br>Zur Vergleichbarkeit der Ergebnisse im Mehrjahreszeitraum, sollen die Bilanzierungsmethoden und Systemgrenzen beibehalten werden. Änderungen der Methodik oder Systemgrenzen müssen begründet und berichtet werden.<br><br>Genauigkeit:<br>Die Ergebnisse sollen soweit möglich frei von Verzerrungen und Unsicherheiten dargestellt werden.",
  customer_faq_q_23: "Warum CO<sub>2</sub>-Bilanz?",
  customer_faq_a_23: "Die CO<sub>2</sub>-Bilanz dient dazu, die größten Emissionsquellen innerhalb des Unternehmens und entlang der vor- und nachgelagerten Wertschöpfungsstufen zu identifizieren. Damit bildet sie die Grundlage für die Entwicklung einer Klimaschutzstrategie, in der Ziele, Maßnahmen und Verantwortlichkeiten zur Reduktion von Treibhausgasemissionen festgelegt werden. In Folgejahren dient sie dazu, zu überprüfen, ob gesetzte Ziele erreicht wurden, in welchen Bereichen Fortschritte erzielt werden konnten und in welchen Bereichen Handlungsbedarf zur CO<sub>2</sub>-Reduktion besteht.",
  customer_faq_q_24: "Warum Systemgrenzen?",
  customer_faq_a_24: "Eine CO<sub>2</sub>-Bilanz erfordert eine klare Festlegung der Systemgrenzen, auf die sich die CO<sub>2</sub>-Bilanz bezieht. Dies beinhaltet organisatorische und operative Systemgrenzen.",
  customer_faq_q_25: "Organisatorische Systemgrenzen",
  customer_faq_a_25: "Sie beschreiben die organisatorische Einheit und den Zeitraum, auf den sich die CO<sub>2</sub>-Bilanz bezieht. Die Systemgrenzen können gemäß der operativen oder finanziellen Kontrolle oder gemäß dem Kapitalanteil festgelegt werden. Als organisatorische Systemgrenze wurde der handelsrechtliche Konsolidierungskreis der Klett Gruppe festgelegt.",
  customer_faq_q_26: "Operative Systemgrenzen",
  customer_faq_a_26: "beschreiben die Emissionsquellen, die innerhalb der organisatorischen Grenzen Berücksichtigung finden. Zur Abgrenzung verschiedener Emissionsquellen unterscheidet das GHG Protocol zwischen drei Kategorien („GLOBAL FOOTPRINT“).",
  customer_faq_q_27: "Scope 1 Emissionen",
  customer_faq_a_27: "In SCOPE 1 werden alle CO<sub>2</sub>-Emissionen ausgewiesen, die direkt durch das bilanzierende Unternehmen gesteuert werden können (direkte CO<sub>2</sub>-Emissionen). Hierunter fallen die Verbrennung fossiler Brennstoffe (mobil und stationär), CO<sub>2</sub>-Emissionen aus chemischen und physikalischen Prozessen sowie Kältemittelleckagen aus Klimaanlagen.",
  customer_faq_q_28: "Scope 2 Emissionen",
  customer_faq_a_28: "In SCOPE 2 werden indirekte CO<sub>2</sub>-Emissionen ausgewiesen, die durch die Verbrennung fossiler Brennstoffe während der Produktion von Strom, Wärme, Kälte und Dampf bei externen Energieversorgern verursacht werden. Durch den Ausweis in einer separaten Kategorie wird eine Doppelzählung beim Vergleich von CO<sub>2</sub>-Emissionen unterschiedlicher Unternehmen vermieden.",
  customer_faq_q_29: "Scope 3 Emissionen",
  customer_faq_a_29: "Alle übrigen CO<sub>2</sub>-Emissionen, die nicht der direkten unternehmerischen Kontrolle unterliegen, werden in SCOPE 3 ausgewiesen (andere indirekte CO<sub>2</sub>-Emissionen). Hierunter fallen z.B. CO<sub>2</sub>-Emissionen, die mit Produkten und Dienstleistungen verbunden sind, die durch das bilanzierende Unternehmen in Anspruch genommen oder verarbeitet werden. Hinzu kommen CO<sub>2</sub>-Emissionen, die mit der Nutzung verkaufter Produkte und Dienstleistungen verbunden sind, wenn dabei direkte CO<sub>2</sub>-Emissionen verursacht werden.",
  customer_faq_q_30: "Datenerfassung und Berechnung",
  customer_faq_a_30: "Die Berechnung der CO<sub>2</sub>-Emissionen erfolgt anhand der Unternehmensfinanzdaten. Diese Daten wurden direkt aus der Unternehmensbuchhaltung übernommen und als primäre Aktivitätsdaten der CO<sub>2</sub>-Berechnung zu Grunde gelegt. Des Weiteren wurden interne Aufzeichnungen und Berechnungen des Kunden als sekundäre Daten und nach Prüfung und Plausibilisierung durch GLOBAL CLIMATE in die Berechnungen mit einbezogen.",
  customer_faq_q_31: "Treibhausgase",
  customer_faq_a_31: "Das für die Treibhausgasbilanzierung verwendete metrische Maß ist das CO<sub>2</sub> Äquivalent (auch CO2e). Damit wird nicht nur das Klimaschädliche Gas CO<sub>2</sub>, sondern auch die Emissionen weiterer klimaschädlicher Treibhausgase auf der Grundlage ihres GWP (Global Warming Potential) in die äquivalente Menge von CO<sub>2</sub> umgerechnet und bilanziert.<br><br><ul><li>Methan (CH4),</li><li>Lachgas (N2O),</li><li>Schwefelhexafluorid (SF6)</li><li>Fluorkohlenwasserstoffe (FKW und H-FKW).</li></ul>",
  customer_faq_q_32: "GWP Wert",
  customer_faq_a_32: "Global Warming Potential- Erderwärmungs- bzw. Treibhauspotenzial.",
  customer_faq_q_33: "Wobei unterstützt GLOBAL CLIMATE",
  customer_faq_a_33: "<ul><li>Erstellung einer vollständigen CO<sub>2</sub>-Bilanz (Treibhausgasbilanz)</li><li>Identifikation von CO₂-Hotspots</li><li>Analyse und der CO₂-Emissionen</li><li>zielgerichteten Steuerung der CO₂-Reduktionsmaßnahmen sowie bei einem</li><li>Nachhaltigkeitsmonitoring der Lieferketten</li></ul>",
  customer_faq_q_34: "Was erhalte ich zum Schluss?",
  customer_faq_a_34: "<ul><li>Prüfungssichere CO<sub>2</sub>-Bilanz</li><li>GLOBAL CLIMATE Gütesiegel für das jeweilige Berichtsjahr</li></ul>"
};
