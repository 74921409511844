import "core-js/stable";
import "regenerator-runtime/runtime";

import App from "./app.vue";
import Toast from "vue-toastification";
import Vue from "vue";
import router from "./router";
import store from "./store";
import { PluginOptions } from "vue-toastification/dist/types/src/types";
import { vuetify } from "./plugins/vuetify";

import "@mdi/font/css/materialdesignicons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "vue-toastification/dist/index.css";
import { i18n } from "@gc/i18n";

const toastOptions: PluginOptions = {
  closeButton: "button",
  closeOnClick: false,
  icon: true,
  pauseOnFocusLoss: true,
  showCloseButtonOnHover: true,
  timeout: 4000,
  transition: {
    enter: "fade-enter-active",
    leave: "fade-leave-active",
    move: "fade-move",
  },
};

Vue.use(Toast, toastOptions);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
