export class PublicURLs {
  home() {
    return `/public`;
  }
  verifyScorecard(onlyPath?: boolean) {
    if (onlyPath) return `scorecard-verification`;
    return `${this.home()}/scorecard-verification`;
  }
  answerCommuterSurvey(onlyPath?: boolean) {
    if (onlyPath) return `answer-commuter-survey`;
    return `${this.home()}/answer-commuter-survey`;
  }
  answerCombinedCommuterSurvey(onlyPath?: boolean) {
    if (onlyPath) return `answer-combined-commuter-survey`;
    return `${this.home()}/answer-combined-commuter-survey`;
  }
  survey(onlyPath?: boolean) {
    if (onlyPath) return `survey`;
    return `${this.home()}/survey`;
  }
}
