/* eslint-disable */
export const messages = {
  system_admin_esg_self_assessment: "ESG Self-Assessment",
  system_admin_esg_self_assessments: "ESG Self-Assessments",
  system_admin_esg_self_assessment_details: "ESG Self-Assessment Details",
  system_admin_esg_self_assessment_body: "Nutzen Sie diesen Abschnitt, um ein ESG Self-Assessment zu starten. Bitte beachten Sie, dass für jedes Unternehmen nur ein ESG Self-Assessment pro Bewertungszeitraum gestartet werden kann. Nach Start eines ESG Self-Assessment wird der Kunde eine Umfrage auf dessen Survey-Plattform sehen können.",
  system_admin_esg_self_assessment_survey_template: "Umfragevorlage",
  system_admin_esg_self_assessment_survey_template_name: "Name der Umfragevorlage",
  system_admin_create_esg_self_assessment: "ESG Self-Assessment erstellen",
  system_admin_create_esg_self_assessment_success: "ESG Self-Assessment erstellt",
  system_admin_esg_self_assessment_validation_one_plus_year: "Der Bewertungszeitraum sollte nicht größer als {0} sein",
};
