/* eslint-disable */
export const messages = {
  system_admin_units_search: "Search by name / symbols / description",
  system_admin_units_only_global_units: "Only Global Units",
  system_admin_units_administration: "Administration",
  system_admin_units_display_name: "Display Name",
  system_admin_units_symbol: "Symbol",
  system_admin_units_symbols: "Symbols",
  system_admin_units_removable: "Removable",
  system_admin_units_unit_deleted: "Unit successfully deleted",
  system_admin_units_deletion_confirmation: "Are you sure want to delete this unit permanently?",
  system_admin_units_deletion_disabled_desc:
    "This unit cannot be deleted as the 'deletable' toggle is currently disabled.",
  system_admin_units_company_info: "Company Info",
  system_admin_units_removable_desc:
    "If the 'deletable' toggle is on, you can delete the unit. If it's off, you cannot delete the unit.",
  system_admin_units_unit_info: "Unit Info",
  system_admin_units_conversion: "Conversion",
  system_admin_units_company_info_must_specified: "Company info must be specified (only if the global switch is off)",
  system_admin_units_unit_name_must_specified: "Unit name on both language must be specified",
  system_admin_units_at_least_symbol_must_specified: "At least 1 symbol is specified",
  system_admin_units_conversion_requirements_are_satisfied: "Conversion requirements are satisfied",
  system_admin_units_unit_name: "Unit Name",
  system_admin_units_symbols_description:
    "Symbols cannot be empty, and the maximum length for each symbol is 32 characters.",
  system_admin_units_conversion_description:
    "This is a list of conversions for this unit. An example for a conversion would be: If the current unit is 'kg' (target unit) and you select 'gr' (source unit) then the coefficient is 1000. This means that 1 kg = 1000 x 1 gr.",
  system_admin_units_conversion_to: "Select source unit",
  system_admin_units_coefficient: "Coefficient",
  system_admin_units_conversion_deletion_confirmation: "Are you sure want to delete this conversion permanently?",
  system_admin_units_symbol_deletion_confirmation: "Are you sure want to delete this symbol permanently?",
  system_admin_units_symbol_tooltip_info:
    "Please enter a text that is 1 to 32 characters long, could contain letters (A-Z, a-z), digits (0-9), special characters (/ . - _ ( ) space), and various non-ASCII characters from around the world.",
};
