<template>
  <v-card max-width="400" class="centering-vertical mx-auto pa-4">
    <v-card-text class="text-center text-h5">
      <v-col cols="12">
        <v-icon size="80">mdi-cancel</v-icon>
      </v-col>
      <v-col cols="12">
        <div>404</div>
        <div>{{ $t("common_not_found_text") }}</div>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-row class="text-center">
        <v-col>
          <v-btn depressed class="dark-blue-btn" :to="URLs.home()">
            {{ $t("common_not_found_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { URLs } from "@gc/urls";

export default Vue.extend({
  name: "NotFoundPage",
  data() {
    return {
      URLs,
    };
  },
});
</script>
