import Vue from "vue";

Vue.filter("commaSeparator", (value: any, lang: string) => {
  return value
    .toString()
    .replace()
    .replace(/\B(?=(\d{3})+(?!\d))/g, lang === "de" ? "." : ",");
});

Vue.filter("fractionSeparator", (value: any, lang: string) => {
  return value.toString().replace(".", lang === "de" ? "," : ".");
});
