import store from "../store";
import { URLs } from "@gc/urls";

import { LicenseType } from "@gc/models";
import { Route } from "vue-router";

class Guards {
  private hasScopesLicense = (to: Route): boolean => {
    const companyId = to.params.selectedCompanyId;
    if (!companyId) return false;

    // Check if the company where the user wants to navigate to, has a license
    const licenses = store.state.company(companyId)?.licenses || [];
    return licenses?.some((license) => license.type === LicenseType.CCF_LICENSE);
  };

  private hasScoresLKSGLicense = (to: Route): boolean => {
    const companyId = to.params.selectedCompanyId;
    if (!companyId) return false;

    // Check if the company where the user wants to navigate to, has a license
    const licenses = store.state.company(companyId)?.licenses || [];
    return licenses?.some((license) => license.type === LicenseType.LKSG_LICENSE);
  };

  private companySelectionGuardRedirectUrl = (to: Route) => {
    const selectedCompanyId = to.params.selectedCompanyId;
    const noCompany = !selectedCompanyId || selectedCompanyId === "undefined";
    if (noCompany && URLs.customer.accountSelection() !== to.path) {
      return URLs.customer.accountSelection();
    }
  };

  public companySelectionAndAuthenticationGuard = (to: Route, from: Route, next: any) => {
    if (!store.state.identity.isAuthenticated) {
      const redirectUrl = to.params.redirect ?? new URL(to.fullPath, window.location.origin).href;
      next(URLs.customer.identity(redirectUrl));
    } else {
      const redirect = this.companySelectionGuardRedirectUrl(to);
      if (redirect) {
        next(redirect);
      } else {
        next();
      }
    }
  };

  public customerWithoutScopesLicenseGuard = (to: Route, from: Route, next: any) => {
    const redirect = this.companySelectionGuardRedirectUrl(to);
    if (redirect) {
      next(redirect);
    } else {
      if (!this.hasScopesLicense(to)) {
        next();
      } else {
        next(URLs.home());
      }
    }
  };

  public customerWithScopesLicenseGuard = (to: Route, from: Route, next: any) => {
    const redirect = this.companySelectionGuardRedirectUrl(to);
    if (redirect) {
      next(redirect);
    } else {
      if (this.hasScopesLicense(to)) {
        next();
      } else {
        next(URLs.customer.sco2pes.info());
      }
    }
  };
}

const guards = new Guards();
export { guards };
