/* eslint-disable */
export const messages = {
  customer_partners_partner_companies: "Partner Companies",
  customer_partners_connect_with_partners: "Connect with partners",
  customer_partners_connected: "Connected",
  customer_partners_pending: "Pending",
  customer_partners_receive_invitation: "I <b>received</b> a invitation-code from a partner organisation.",
  customer_partners_send_invitation: "I want to <b>invite</b> a partner organisation by sending a invitation-code.",
  customer_partners_receive_invitation_1:
    "In order to connect your organisation with another one please insert the invitation-code you received by email:",
  customer_partners_invitation_code: "Invitation Code",
  customer_partners_receive_invitation_2: "The organisation  has been added to you partner organisations.",
  customer_partners_receive_invitation_3: "You can add a name for  the organisation and add some more data here:",
  customer_partners_next: "Next",
  customer_partners_partner_information: "Partner Details",
  customer_partners_at_least_one_product_selected: "At least one product should be selected.",
  customer_partners_company_name: "Company Name",
  customer_partners_product: "Product",
  customer_partners_organisation_name: "Organisation Name",
  customer_partners_invitation_title: "Partner invitation process",
  customer_partners_invitation_point_1: "1. You invite Partners by Excel file upload",
  customer_partners_invitation_point_2: "2. The GLOBAL SUITE sends invitation E-Mails to the Partners",
  customer_partners_invitation_point_3:
    "3. Partners register at the GLOBAL SUITE (if they not already registered) or they accept the invitation",
  customer_partners_invitation_point_4: "4. The partnership is established and you can start sharing data",
  customer_partners_received_information: "Received an invitation?",
  customer_partners_import_partners: "Import Partners",
  customer_partners_import_partners_description:
    "Partners can be imported via excel file. Use this excel template for file upload:",
  customer_partners_copy_registration_url: "Copy registration link",
  customer_partners_copied_registration_url: "Registration link copied",
  customer_partners_unique_id: "Unique ID",
  customer_partners_invitation_information: "Invitation Information",
  customer_partners_dashboard: "Partner Dashboards",
  customer_partners_upload_success: "Data imported successfully",
  customer_partners_search: "Search Company Name, Unique ID, E-Mail, Contact Person",
};
