import { SelectedCompanyMethod } from "../..";

export class CustomerPartnerURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}
  home() {
    return `${this.customerHome()}/partners`;
  }
  partnerDetails(id: string) {
    return `${this.home()}/${id}`;
  }
  partnerDashboard(dashboardType = ":dashboardType") {
    return `${this.home()}/dashboard/${dashboardType}`;
  }
}
