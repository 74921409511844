export class ArrayHelper {
  static equals<T1, T2>(a: T1[], b: T2[], comparator: (o1: T1, o2: T2) => boolean = null): boolean {
    if (a.length !== b.length) return false;

    const c = comparator ?? ((o1, o2) => (o1 as any) === (o2 as any));

    for (let i = 0; i < a.length; i++) {
      if (!c(a[i], b[i])) return false;
    }
    return true;
  }
}
