import moment from "moment";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const formatDate = (date: number | string, local: string): string => {
  return moment(date).format("DD.MM.YYYY");
};

export const formatDateTime = (timestamp: number, local: string): string => {
  if (local === "de") {
    return moment(timestamp).format("DD.MM.YYYY, HH:mm");
  }
  return moment(timestamp).format("DD.MM.YYYY, hh:mm A");
};

export const yearsList = (count: number, descending = true, startYear = moment().format("YYYY")) => {
  const years = [];
  for (let i = 0; i < count; i++) {
    if (descending) {
      years.push(moment(startYear).subtract(i, "year").format("YYYY"));
    } else {
      years.push(moment(startYear).add(i, "year").format("YYYY"));
    }
  }
  return years;
};
