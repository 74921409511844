import { Api, ReturnFileValue } from "./api";
import { ApiInfo } from "./api-info";

export class GenericApi {
  constructor(private readonly api: Api) {}

  async download(arg: { url: string; fallbackFileName: string }): Promise<ReturnFileValue> {
    return this.api.download(arg);
  }

  async call<TResult>(arg: ApiInfo): Promise<TResult> {
    let response: any;
    if (arg.method === "get") {
      response = await this.api.get(arg);
    } else if (arg.method === "post") {
      response = await this.api.post(arg);
    } else if (arg.method === "post-multipart") {
      response = await this.api.postMultipart(arg);
    } else if (arg.method === "delete") {
      response = await this.api.delete(arg);
    } else if (arg.method === "patch") {
      response = await this.api.patch(arg);
    } else if (arg.method === "put") {
      response = await this.api.put(arg);
    } else if (arg.method === "put-multipart") {
      response = await this.api.putMultipart(arg);
    } else {
      throw arg.method + " is Not Implemented!";
    }
    return response as TResult;
  }
}
