import { isNil, isString, isNumber, isNaN, isBoolean } from "lodash";
import { firstBy } from "thenby";

type Direction = "asc" | "desc";

const compareUnknown = (a: any, b: any): number => {
  if (isNil(a) || isNil(b)) {
    return 0;
  }
  if (isString(a) || isString(b)) {
    return a.localeCompare(b, undefined, { numeric: true });
  }
  if (isNumber(a) && isNumber(b)) {
    if (isNaN(a) && isNaN(b)) {
      return 0;
    }
    if (isNaN(a)) {
      return -1;
    }
    if (isNaN(b)) {
      return 1;
    }
    return a - b;
  }
  if (isBoolean(a) && isBoolean(b)) {
    if (a === b) {
      return 0;
    }
    if (a && !b) {
      return 1;
    }
    return -1;
  }
  return JSON.stringify(a).localeCompare(JSON.stringify(b));
};

const build = (cols: { col: string; direction: Direction }[]) => {
  const c0 = cols[0];
  let k = firstBy((a: any, b: any) => {
    return compareUnknown(a[c0.col], b[c0.col]);
  }, c0.direction);
  for (let i = 1; i < cols.length; i++) {
    const c = cols[i];
    k = k.thenBy((a: any, b: any) => {
      return compareUnknown(a[c.col], b[c.col]);
    }, c.direction);
  }
  return k;
};

export const sortObjects = <T>(
  objects: T[],
  columns: { column: Extract<keyof T, string>; ascending?: boolean }[]
): T[] => {
  if (isNil(objects) || objects.length === 0 || isNil(columns) || columns.length === 0) {
    return objects;
  }
  const cols: { col: Extract<keyof T, string>; direction: Direction }[] = columns.map(function (c) {
    return {
      col: c.column,
      direction: c.ascending ? "asc" : "desc",
    };
  });
  const result: T[] = [];
  objects.forEach((o) => {
    result.push(o);
  });
  result.sort(build(cols));
  return result;
};
