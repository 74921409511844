import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "lodash";
import { stringify } from "qs";
import { StringHelper } from "../string-helper";
import { FormDataExtensions } from "./form-data-extensions";
import { handleHttpError, On401 } from "./handle-error";
import { ContentDisposition } from "../content-disposition";
import { ApiInfo } from "./api-info";

export type HeaderProvider = () => Record<string, string>;
export type ReturnFileValue = {
  data: Blob;
  fileName: string;
};
export type ReturnValue = ReturnFileValue | any;
type Args = Omit<ApiInfo, "method">;

export class Api {
  private readonly uri: string;
  private readonly logFailures: boolean;

  constructor(
    serverUrl: string,
    logAxiosFailures: boolean,
    private readonly on401?: On401,
    private readonly headerProvider?: HeaderProvider
  ) {
    this.uri = StringHelper.instance.ensureSuffix(serverUrl, "/");
    this.logFailures = logAxiosFailures;
  }

  get endpoint(): string {
    return this.uri;
  }

  private getClientConfig(noTimeout?: boolean): AxiosRequestConfig {
    const headers: any = this.headerProvider?.() ?? {};
    headers["X-Requested-With"] = "XMLHttpRequest";
    return {
      baseURL: this.endpoint,
      timeout: noTimeout ? undefined : 1000 * 60 * 5,
      headers: headers,
    };
  }

  private getClient(noTimeout?: boolean): AxiosInstance {
    return axios.create(this.getClientConfig(noTimeout));
  }

  private static paramSerializer(params: any): string {
    if (isNil(params)) {
      return "";
    }
    return stringify(params, {
      arrayFormat: "brackets",
    });
  }

  private handleError(error: any, o?: { isPermaLink?: boolean }): Promise<{ errors: string[] }> {
    return handleHttpError({
      error,
      logInConsole: this.logFailures,
      on401: this.on401 ?? (() => {}),
      isPermaLink: o.isPermaLink,
    });
  }

  async download({ url, fallbackFileName }: { url: string; fallbackFileName: string }): Promise<ReturnFileValue> {
    const client = axios.create({
      timeout: undefined,
    });
    const response = await client.get(url, {
      responseType: "blob",
    });
    return this.returnData(response, { fallbackFileName });
  }

  async get(options: Omit<Args, "data">): Promise<ReturnValue> {
    try {
      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params,
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        options
      );
      const response = await client.get(options.path, config);
      return this.returnData(response, options);
    } catch (e) {
      throw (await this.handleError(e, options)).errors.join("<br>");
    }
  }

  async post(options: Args): Promise<ReturnValue> {
    try {
      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        options
      );
      const response = await client.post(options.path, options.data, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  async put(options: Args): Promise<ReturnValue> {
    try {
      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        options
      );
      const response = await client.put(options.path, options.data, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  async delete(options: Args): Promise<ReturnValue> {
    try {
      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        options
      );
      const response = await client.delete(options.path, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  async patch(options: Args): Promise<ReturnValue> {
    try {
      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        options
      );
      const response = await client.patch(options.path, options.data, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  async postMultipart(options: Args): Promise<ReturnValue> {
    try {
      // create form-data from payload
      const formData = new FormData();
      FormDataExtensions.put(formData, options.data);

      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        { ...options, multipart: true }
      );
      const response = await client.post(options.path, formData, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  async putMultipart(options: Args): Promise<any> {
    try {
      // create form-data from payload
      const formData = new FormData();
      FormDataExtensions.put(formData, options.data);

      const client = this.getClient(!!options.fallbackFileName);
      const config = this.configureRequest(
        {
          params: options.params ?? {},
          paramsSerializer: Api.paramSerializer,
          withCredentials: true,
        },
        { ...options, multipart: true }
      );
      const response = await client.put(options.path, formData, config);
      return this.returnData(response, options);
    } catch (error) {
      throw (await this.handleError(error, options)).errors.join("<br>");
    }
  }

  private configureRequest(
    config: AxiosRequestConfig,
    {
      fallbackFileName,
      multipart,
    }: Pick<Args, "fallbackFileName"> & {
      multipart?: true;
    }
  ): AxiosRequestConfig {
    const result: AxiosRequestConfig = { ...config };
    if (multipart) {
      result.headers = {
        ...result.headers,
        "content-type": undefined,
      };
    }
    if (fallbackFileName?.length > 0) {
      result.responseType = "blob";
    }
    return result;
  }

  private returnData(
    response: AxiosResponse,
    { fallbackFileName }: Pick<Args, "fallbackFileName">
  ): Promise<ReturnValue> {
    if (!fallbackFileName?.length) {
      return response.data;
    }

    // if server responds with a file, then return blob
    const fileName = ContentDisposition.instance.extractFileName(
      response.headers["content-disposition"],
      fallbackFileName
    );
    return {
      //@ts-ignore
      data: response.data,
      fileName,
    };
  }
}
