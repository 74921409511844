import { SelectedCompanyMethod } from "../..";

export class CustomerAdministrationURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}
  home() {
    return `${this.customerHome()}/administration`;
  }
  companyDetails() {
    return `${this.home()}/company-details`;
  }
  users() {
    return `${this.home()}/users`;
  }
  licenses() {
    return `${this.home()}/licenses`;
  }
}
