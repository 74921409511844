/* eslint-disable */
export const messages = {
  customer_administration_users: "Users",
  customer_administration_invite_user: "Invite user",
  customer_administration_invite: "Invite",
  customer_administration_profile_status: "Profile status",
  customer_administration_basic_access: "Basic Access",
  customer_administration_administration_access: "Administration Access",
  customer_administration_sco2pes_access: "GLOBAL FOOTPRINT Access",
  customer_administration_compliance_access: "GLOBAL COMPLIANCE Access",
  customer_administration_auditor_access: "Auditor Access",
  customer_administration_completed: "Completed",
  customer_administration_invited: "Invited",
  customer_administration_identifier: "Identifier",
  customer_administration_functions: "Functions",
  customer_administration_view_dashboard: "View dashboard",
  customer_administration_role: "Role",
  customer_administration_deactivate: "Deactivate",
  customer_administration_deactivated: "Deactivated",
  customer_administration_deactivate_modal_title: "Deactivate API Key",
  customer_administration_deactivate_modal_description:
    "Deactivation of an API Key can not be undone. <br>Do you really want to deactivate the API Key?",
  customer_administration_api_key_deactivated: "API Key successfully deactivated",
  customer_administration_new_api_key: "New API Key",
  customer_administration_download_api_key: "Download API Key",
  customer_administration_new_api_key_description:
    "API Keys enable external systems to communicate with GLOBAL CLIMATE API .<br>Should a new API key be created?",
  customer_administration_download_api_key_description:
    "A new API Key has been created. <br> Download the API Key and save it in a secure way. GLOBAL CLIMATE stores an encrypted version of the the key and is not able ro decrypt or recreate the information.",
  customer_administration_invite_user_description:
    "If you invite a new user an email with registration link will be send to the user. Enter the E-Mail of the user:",
  customer_administration_what_roles_should_get_assigned: "What roles should get assigned to the new user?",
  customer_administration_cant_edit_own_roles: "Users are not allowed to edit their own roles.",
  customer_administration_view_users_and_api_keys: "View users, API keys, licenses and company codes",
  customer_administration_view_and_create_support_tickets: "View and create support tickets",
  customer_administration_view_csrd_tool: "View and observe CSRD Tool",
  customer_administration_view_invite_and_edit_users: "View, invite and edit users",
  customer_administration_view_create_and_deactivate_api_keys: "View, create and deactivate API keys",
  customer_administration_view_and_manage_licenses: "View and order licenses",
  customer_administration_view_create_and_edit_company_code: "View, create and edit company codes",
  customer_administration_view_and_edit_co2_database: "View and edit CO<sub>2</sub> Database",
  customer_administration_view_and_edit_suppliers_and_transport_routes:
    "View, create and edit Suppliers and Transport Routes",
  customer_administration_view_and_edit_co2_bookings: "View, create and edit Unit and Financials Bookings",
  customer_administration_view_and_edit_commuter_surveys: "View, create and edit Commuter Surveys",
  customer_administration_download_report: "Download Report",
  customer_administration_view_create_and_edit_assessments: "View, create and edit ESG-Ratings",
  customer_administration_view_and_edit_partners: "View and edit partners",
  customer_administration_share_assessments_with_partners: "Share ESG-Rating results with partners",
  customer_administration_company_details: "Company Details",
  customer_administration_user: "User",
  customer_administration_license_required: "License required",
  customer_administration_licenses: "Licenses",
  customer_administration_license_package: "License Package",
  customer_administration_license_list: "License List",
  customer_administration_employees: "Employees",
  customer_administration_buy_license: "Buy License",
  customer_administration_license_status: "License Status",
  customer_administration_license_info: "License Info",
  customer_administration_license_costs: "License costs",
  customer_administration_plus_vat: "plus VAT",
  customer_administration_ordered_by: "Ordered By",
  customer_administration_invoice_status: "Invoice Status",
  customer_administration_ordered: "Ordered",
  customer_administration_active: "Active",
  customer_administration_void: "Void",
  customer_administration_download_invoice: "Download Invoice",
  customer_administration_open_payment_page: "Open Payment Page",
  customer_administration_open: "Open",
  customer_administration_paid: "Paid",
  customer_administration_the_license_includes: "The license includes",
  customer_administration_please_validate_billing_info: "Please validate and adapt the billing information",
  customer_administration_small: "Small",
  customer_administration_medium: "Medium",
  customer_administration_large: "Large",
  customer_administration_invoice_email: "Invoice Email",
  customer_administration_tax_id: "Tax ID",
  customer_administration_buy_license_description_1:
    "By continuing an invoice will be send to the provided email address.",
  customer_administration_buy_license_description_2:
    "You can either pay via our payment service provider Stripe (credit card) or by bank transfer. If you transfer the amount directly, the license will be unlocked as soon as your payment is booked.",
  customer_administration_thanks_for_ordering_license: "Thank you for ordering a 1 year license!",
  customer_administration_we_will_send_you_the_invoice:
    "We will send you the invoice to the given email ({0}), but you can also download it here.",
  customer_administration_payment_description:
    "If you pay via our payment service provider Stripe, we can activate your license immediately.",
  customer_administration_activating_license_after_payment:
    "If you transfer the amount directly to the bank account specified in the invoice, the license will be unlocked as soon as your payment is received.",
  customer_administration_pay_now: "Pay now",
  customer_administration_next: "Next",
  customer_administration_requested_at: "Order Date",
  customer_administration_validity_period: "Validity Period",
  customer_administration_invalid_vat_tax_id: "Invalid VAT Tax ID",
  customer_administration_invitation_language: "Invitation mail language",
};
