/* eslint-disable */
export const messages = {
  system_admin_survey_surveys: "Surveys",
  system_admin_survey_details: "Survey Details",
  system_admin_survey_state: "State",
  system_admin_survey_state_in_progress: "In Progress",
  system_admin_survey_state_in_review: "In Review",
  system_admin_survey_state_completed: "Completed",
  system_admin_survey_company: "Company",
  system_admin_survey_survey: "Survey",
  system_admin_survey_result: "Result",
  system_admin_survey_events: "Events",
  system_admin_survey_evaluation_period: "Evaluation Period",
  system_admin_survey_survey_page: "Survey Page",
  system_admin_survey_finalise_survey: "Finalise Survey",
  system_admin_survey_calculated_result: "The following result has been calculated:",

  system_admin_survey_edit_kpi_value: "Edit KPI Value",
  system_admin_survey_kpi: "KPI",
  system_admin_survey_expression: "Expression",
  system_admin_survey_expression_parameters: "Expression Parameters",
  system_admin_survey_expression_result: "Expression Result",
  system_admin_survey_expression_errrors: "Errors",
  system_admin_survey_edit_kpi_info: "Calculated values can be overwritten.",
  system_admin_survey_kpi_value: "KPI Value",

  system_admin_survey_edit_score_value: "Edit Score Value",
  system_admin_survey_category: "Category",
  system_admin_survey_max_score: "Max Score",
  system_admin_survey_edit_score_info: `Calculated values can be overwritten.<br /> Some scores need to be approved manually, e.g. if a file upload needs to be verified.`,

  system_admin_survey_customers_must_submit_answers: "Customers must submit all answers first",

  system_admin_survey_kpis: "KPIs",
  system_admin_survey_with_errors: "With errors",
  system_admin_survey_without_errors: "Without errors",
  system_admin_survey_no_kpis: "No KPIs",
  system_admin_survey_kpis_title: "Title",
  system_admin_survey_kpis_expression: "Expression",
  system_admin_survey_kpis_calculated_value: "Calculated Value",
  system_admin_survey_kpis_value: "Value",
  system_admin_survey_kpis_errors: "Errors",
  system_admin_survey_kpis_actions: "",

  system_admin_survey_scores: "Scores",
  system_admin_survey_approved: "Approved",
  system_admin_survey_not_approved: "Not approved",
  system_admin_survey_no_scores: "No scores",
  system_admin_survey_scores_category: "Score Category",
  system_admin_survey_scores_expression: "Expression",
  system_admin_survey_scores_calculated_value: "Calculated Value",
  system_admin_survey_scores_score: "Score",
  system_admin_survey_scores_errors: "Errors",
  system_admin_survey_scores_approved: "Approved",
};
