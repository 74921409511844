import { messages as auth } from "./public/auth/de";
import { messages as common } from "./common/de";
import { messages as systemAdmin } from "./protected/system-admin/de";
import { messages as customer } from "./protected/customer/de";
import { messages as profile } from "./protected/profile/de";
import { messages as navigation } from "./protected/navigation/de";
import { messages as components } from "./components/de";

export const de = {
  ...components,
  ...auth,
  ...common,
  ...systemAdmin,
  ...customer,
  ...profile,
  ...navigation,
};
